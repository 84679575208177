<script lang="ts" setup>
import { UModalConfirm } from "#components";

const { t } = useI18n();
const shapesStore = useShapesStore();
const canClear = computed(() => shapesStore.shapes.length > 0);
const modal = useModal();

const onClick = () => {
  modal.open(UModalConfirm, {
    heading: t("pages.playlists.telestrations.telestration_delete_heading"),
    icon: "telestration",
    variant: "delete",
    description: t("pages.playlists.telestrations.telestration_delete_confirm"),
    confirmFn: () => shapesStore.clearShapes(),
    confirmLabel: t("labels.clear_all"),
    cancelLabel: t("labels.cancel"),
  });
};
</script>

<template>
  <TButton
    :leading-icon="{ name: 'close', class: 'size-3' }"
    :label="t('labels.clear_all')"
    :disabled="!canClear"
    @click="onClick"
  />
</template>
