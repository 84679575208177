<script lang="ts" setup>
import { ShapeType } from "@/telestrations/types/canvas";
import type { PxlIconName } from "@/common/components/U/Icon";

const { t } = useI18n();
const props = defineProps<{
  tool: Tool;
}>();

const toolsStore = useToolsStore();

const isSelected = computed(() => {
  return toolsStore.selectedTool === props.tool;
});

function onClick() {
  toolsStore.selectTool(props.tool);
}

const iconsMap: Record<Tool, PxlIconName> = {
  selection: "cursor",
  [ShapeType.Line]: "free-draw",
  [ShapeType.Rectangle]: "rectangle",
  [ShapeType.Circle]: "circle",
  [ShapeType.Arrow]: "arrow-top-right",
  [ShapeType.Text]: "text",
};

const i18nLabelsMap: Record<Tool, string> = {
  selection: "labels.select",
  [ShapeType.Line]: "labels.free_draw",
  [ShapeType.Rectangle]: "labels.rectangle",
  [ShapeType.Circle]: "labels.circle",
  [ShapeType.Arrow]: "labels.arrow",
  [ShapeType.Text]: "labels.text",
};
</script>

<template>
  <TButton
    :variant="isSelected ? 'primary' : 'clean'"
    :icon="iconsMap[props.tool]"
    :tooltip="t(i18nLabelsMap[props.tool])"
    @click="onClick"
  />
</template>
