<script lang="ts" setup>
import { UButton } from "#components";

type UButtonProps = InstanceType<typeof UButton>["$props"];

const props = defineProps<{
  disabled?: boolean;
  tooltip?: string;
} & /* @vue-ignore */ UButtonProps>();
</script>

<template>
  <UButton
    v-tooltip="props.tooltip"
    v-bind="props"
    :class="props.disabled ? 'text-neutral-light-800' : ''"
    :size="props.size || 'sm'"
    :variant="props.variant || 'clean'"
  />
</template>
