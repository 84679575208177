import type Konva from "konva";
import { Arrow } from "konva/lib/shapes/Arrow";
import { Circle } from "konva/lib/shapes/Circle";
import { Line } from "konva/lib/shapes/Line";
import { Path } from "konva/lib/shapes/Path";
import { Rect } from "konva/lib/shapes/Rect";
import { Text } from "konva/lib/shapes/Text";
import { Transformer } from "konva/lib/shapes/Transformer";

export function getSVGStringFromKonvaStage(stage: Konva.Stage): string {
  let shapesCount = 0;
  let svgString = `<svg width="${stage.width()}" height="${stage.height()}" xmlns="http://www.w3.org/2000/svg">`;

  stage.getLayers().forEach((layer) => {
    const children = layer.getChildren();
    shapesCount += children.length;
    children.forEach((shape) => {
      if (shape instanceof Transformer) return;
      const transformStr = `transform="rotate(${shape.rotation()}) scale(${shape.scaleX()}, ${shape.scaleY()})"`;
      if (shape instanceof Path) {
        svgString += `<path ${transformStr} id="${shape.id()}" d="${shape.data()}" fill="${shape.fill() || "transparent"}" stroke="${shape.stroke() || "black"}" stroke-width="${shape.strokeWidth()}" />`;
      }
      else if (shape instanceof Circle) {
        svgString += `<circle stroke-dasharray="${shape.dash()}" ${transformStr} id="${shape.id()}" cx="${shape.x()}" cy="${shape.y()}" r="${shape.radius()}" fill="${shape.fill() || "transparent"}" stroke="${shape.stroke() || "black"}" stroke-width="${shape.strokeWidth()}" />`;
      }
      else if (shape instanceof Rect) {
        svgString += `<rect stroke-dasharray="${shape.dash()}" ${transformStr} id="${shape.id()}" x="${shape.x()}" y="${shape.y()}" width="${shape.width()}" height="${shape.height()}" fill="${shape.fill() || "transparent"}" stroke="${shape.stroke() || "black"}" stroke-width="${shape.strokeWidth()}" />`;
      }
      else if (shape instanceof Text) {
        svgString += `<text ${transformStr} id="${shape.id()}" x="${shape.x()}" y="${shape.y()}" font-size="${shape.fontSize()}" font-family="${shape.fontFamily()}" fill="${shape.fill() || "black"}" text-anchor="${shape.align()}">${shape.text()}</text>`;
      }
      else if (shape instanceof Arrow) {
        svgString += konvaArrowToSVG(shape, transformStr);
      }
      else if (shape instanceof Line) {
        const points = shape.points();
        let pointsString = "";
        for (let i = 0; i < points.length; i += 2) {
          pointsString += `${points[i]},${points[i + 1]} `;
        }
        svgString += `<${shape.closed() ? "polygon" : "polyline"} id="${shape.id()}" ${transformStr} x="${shape.attrs.startX}" y="${shape.attrs.startY}" points="${pointsString.trim()}" fill="${shape.fill() || "transparent"}" stroke="${shape.stroke() || "black"}" stroke-width="${shape.strokeWidth()}" />`;
      }
    });
  });

  if (shapesCount <= 1) return "";

  svgString += "</svg>";

  return svgString;
}

function konvaArrowToSVG(arrow: Arrow, transformStr: string): string {
  const id = arrow.id();
  const points = arrow.points();
  const stroke = arrow.stroke();
  const strokeWidth = arrow.strokeWidth();

  let svgPath = `M${points[0]},${points[1]}`;
  for (let i = 2; i < points.length; i += 2) {
    svgPath += ` L${points[i]},${points[i + 1]}`;
  }

  const svgString = `<path stroke-dasharray="${arrow.dash()}" ${transformStr} id="${id}" stroke="${stroke}"  stroke-width="${strokeWidth}" d="${svgPath}" />`;
  return svgString;
}
