<script lang="ts" setup>
const { t } = useI18n();
const toolsStore = useToolsStore();

const [minimized, toggleMinimized] = useToggle(false);

const exitTelestrationMode = useTelestrationStore().exitTelestrationMode;
</script>

<template>
  <div
    v-if="minimized"
    class="absolute inset-x-2 bottom-2 z-[100] flex gap-2 text-white"
  >
    <div class="flex rounded bg-black/[0.72] p-2 backdrop-blur-sm">
      <TButton :tooltip="t('labels.show')" icon="chevron-right" @click="toggleMinimized(false)" />
    </div>
  </div>
  <div v-else class="absolute inset-x-2 bottom-2 z-[100] flex gap-2 text-white">
    <div
      class="flex w-full justify-between rounded bg-black/[0.72] p-2 backdrop-blur-sm"
    >
      <div class="flex items-start">
        <div class="flex items-start gap-2">
          <TButtonTool
            v-for="tool in toolsStore.availableTools"
            :key="tool"
            :tool="tool"
          />
        </div>
        <div class="flex size-8 items-center justify-center">
          <UDivider vertical class="!h-6 w-8" darker />
        </div>
        <div class="flex items-start gap-2">
          <TButtonUndoRedo />
        </div>
      </div>

      <div class="flex">
        <TButtonClearAll />
        <TButtonSave />
      </div>
    </div>

    <div class="flex rounded bg-black/[0.72] p-2 backdrop-blur-sm">
      <TButton
        :tooltip="t('labels.exit')"
        icon="exit"
        @click="exitTelestrationMode"
      />
      <TButton
        :tooltip="t('labels.hide')"
        icon="chevron-left"
        @click="toggleMinimized(true)"
      />
    </div>
  </div>
</template>
