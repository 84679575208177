<script lang="ts" setup>
import type Konva from "konva";
import type { CSSProperties } from "vue";
import { nextTick } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import type { Shape } from "@/telestrations/types/canvas";

const props = defineProps<{
  shape: Shape;
}>();

const textareaShown = ref(false);
const textareaRef = ref<HTMLTextAreaElement | null>(null);
const textareaStyles = ref<Partial<CSSProperties> | null>(null);
const shapesStore = useShapesStore();
/**
 * This is a workaround for missclicking on the textarea when it's shown.
 * TODO: Rewrite to a better solution.
 */
const {
  start: startTextareaAvailabilityCountdown,
  remaining: remainingTextareaAvailabilityCountdown,
  reset: resetTextareaAvailabilityCountdown,
} = useCountdown(1);

function handleDragEnd(e: Event) {
  shapesStore.onShapeDragEnd(e, props.shape);
}

function handleShapeClick() {
  shapesStore.selectShape(props.shape);
}
function handleTransformEnd(e: Event) {
  shapesStore.onShapeTransformEnd(e);
}
function handleShapeDoubleClick(e: Event) {
  const textNode = e.target as Konva.Text | null;

  if (!textNode) {
    console.warn("No node found");
    return;
  }

  const stage = textNode.getStage();

  if (!stage) {
    console.warn("No stage found");
    return;
  }

  // create textarea over canvas with absolute position

  // first we need to find position for textarea
  // how to find it?

  // at first lets find position of text node relative to the stage:
  const textPosition = textNode.getAbsolutePosition();

  // then lets find position of stage container on the page:
  const stageBox = stage.container().getBoundingClientRect();

  // so position of textarea will be the sum of positions above:
  const areaPosition = {
    x: stageBox.left + textPosition.x,
    y: stageBox.top + textPosition.y,
  };

  textareaStyles.value = {
    position: "absolute",
    zIndex: "100",
    top: areaPosition.y + "px",
    left: areaPosition.x + "px",
    width: textNode.width() + "px",
    height: textNode.height() + "px",
  };

  textareaShown.value = true;
  startTextareaAvailabilityCountdown();

  nextTick(() => {
    if (!textareaRef.value) return;
    textareaRef.value.focus();
    textareaRef.value.value = textNode.text();
  });
}

function updateTextNodeAndCloseTextarea() {
  if (remainingTextareaAvailabilityCountdown.value > 0) {
    return;
  }
  if (!textareaRef.value || !textareaShown.value) return;

  const textNode = shapesStore.getShapeNode(props.shape) as Konva.Text | null;
  if (!textNode) {
    console.warn("No node found");
    return;
  }
  if (!textareaRef.value) return;

  textNode.text(textareaRef.value.value);
  textareaShown.value = false;
  resetTextareaAvailabilityCountdown();
}
</script>

<template>
  <v-text
    :config="props.shape"
    @dragstart="shapesStore.onShapeDragStart"
    @dragmove="shapesStore.onShapeDragMove"
    @dragend="handleDragEnd"
    @click="handleShapeClick"
    @transformend="handleTransformEnd"
    @dblclick="handleShapeDoubleClick"
    @dbltap="handleShapeDoubleClick"
  />
  <Teleport to="body">
    <textarea
      v-if="textareaShown"
      ref="textareaRef"
      v-on-click-outside="updateTextNodeAndCloseTextarea"
      class="absolute z-20"
      :style="textareaStyles"
      @keydown.enter="updateTextNodeAndCloseTextarea"
    />
  </Teleport>
</template>
