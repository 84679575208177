
// @ts-nocheck


export const localeCodes =  [
  "en",
  "es",
  "de",
  "fr",
  "it",
  "he",
  "hu",
  "nl",
  "uk-UA"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_45GB_46json_6ac858c2",
      load: () => import("#nuxt-i18n/6ac858c2" /* webpackChunkName: "locale_en_45GB_46json_6ac858c2" */),
      cache: true
    },
    {
      key: "locale_en_46json_739ffd66",
      load: () => import("#nuxt-i18n/739ffd66" /* webpackChunkName: "locale_en_46json_739ffd66" */),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_45ES_46json_3f9b9cc6",
      load: () => import("#nuxt-i18n/3f9b9cc6" /* webpackChunkName: "locale_es_45ES_46json_3f9b9cc6" */),
      cache: true
    },
    {
      key: "locale_es_46json_781cd081",
      load: () => import("#nuxt-i18n/781cd081" /* webpackChunkName: "locale_es_46json_781cd081" */),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_45DE_46json_d2c564d0",
      load: () => import("#nuxt-i18n/d2c564d0" /* webpackChunkName: "locale_de_45DE_46json_d2c564d0" */),
      cache: true
    },
    {
      key: "locale_de_46json_49cc5103",
      load: () => import("#nuxt-i18n/49cc5103" /* webpackChunkName: "locale_de_46json_49cc5103" */),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_45FR_46json_8c87dfff",
      load: () => import("#nuxt-i18n/8c87dfff" /* webpackChunkName: "locale_fr_45FR_46json_8c87dfff" */),
      cache: true
    },
    {
      key: "locale_fr_46json_020353cd",
      load: () => import("#nuxt-i18n/020353cd" /* webpackChunkName: "locale_fr_46json_020353cd" */),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_45IT_46json_f2292936",
      load: () => import("#nuxt-i18n/f2292936" /* webpackChunkName: "locale_it_45IT_46json_f2292936" */),
      cache: true
    },
    {
      key: "locale_it_46json_3d1d6b88",
      load: () => import("#nuxt-i18n/3d1d6b88" /* webpackChunkName: "locale_it_46json_3d1d6b88" */),
      cache: true
    }
  ],
  he: [
    {
      key: "locale_he_46json_d0054cb3",
      load: () => import("#nuxt-i18n/d0054cb3" /* webpackChunkName: "locale_he_46json_d0054cb3" */),
      cache: true
    },
    {
      key: "locale_zod_45he_46json_602e976b",
      load: () => import("#nuxt-i18n/602e976b" /* webpackChunkName: "locale_zod_45he_46json_602e976b" */),
      cache: true
    }
  ],
  hu: [
    {
      key: "locale_hu_45HU_46json_ecf4a87f",
      load: () => import("#nuxt-i18n/ecf4a87f" /* webpackChunkName: "locale_hu_45HU_46json_ecf4a87f" */),
      cache: true
    },
    {
      key: "locale_hu_46json_1608dd00",
      load: () => import("#nuxt-i18n/1608dd00" /* webpackChunkName: "locale_hu_46json_1608dd00" */),
      cache: true
    }
  ],
  nl: [
    {
      key: "locale_nl_46json_800fd4c6",
      load: () => import("#nuxt-i18n/800fd4c6" /* webpackChunkName: "locale_nl_46json_800fd4c6" */),
      cache: true
    },
    {
      key: "locale_zod_45nl_46json_d027b451",
      load: () => import("#nuxt-i18n/d027b451" /* webpackChunkName: "locale_zod_45nl_46json_d027b451" */),
      cache: true
    }
  ],
  "uk-UA": [
    {
      key: "locale_uk_45UA_46json_5c8caab7",
      load: () => import("#nuxt-i18n/5c8caab7" /* webpackChunkName: "locale_uk_45UA_46json_5c8caab7" */),
      cache: true
    },
    {
      key: "locale_uk_45UA_46json_a90bd6fe",
      load: () => import("#nuxt-i18n/a90bd6fe" /* webpackChunkName: "locale_uk_45UA_46json_a90bd6fe" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/f39e0f36" /* webpackChunkName: "config_i18n_46config_46ts_f39e0f36" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/en-GB.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/en.json"
      ],
      language: "en",
      dir: "ltr"
    },
    {
      code: "es",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/es-ES.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/es.json"
      ],
      language: "es",
      dir: "ltr"
    },
    {
      code: "de",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/de-DE.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/de.json"
      ],
      language: "de",
      dir: "ltr"
    },
    {
      code: "fr",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/fr-FR.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/fr.json"
      ],
      language: "fr",
      dir: "ltr"
    },
    {
      code: "it",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/it-IT.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/it.json"
      ],
      language: "it",
      dir: "ltr"
    },
    {
      code: "he",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/he.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/zod-he.json"
      ],
      language: "he",
      dir: "rtl"
    },
    {
      code: "hu",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/hu-HU.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/hu.json"
      ],
      language: "hu",
      dir: "ltr"
    },
    {
      code: "nl",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/nl.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/zod-nl.json"
      ],
      language: "it",
      dir: "ltr"
    },
    {
      code: "uk-UA",
      files: [
        "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/uk-UA.json",
        "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/uk-UA.json"
      ],
      language: "uk-UA",
      dir: "ltr"
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: true,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: [
    {
      langDir: "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales",
      locales: [
        {
          code: "de"
        },
        {
          code: "en"
        },
        {
          code: "es"
        },
        {
          code: "fr"
        },
        {
          code: "hu"
        },
        {
          code: "it"
        },
        {
          code: "uk-UA"
        }
      ]
    }
  ]
}

export const normalizedLocales = [
  {
    code: "en",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/en-GB.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/en.json",
        cache: undefined
      }
    ],
    language: "en",
    dir: "ltr",
    file: undefined
  },
  {
    code: "es",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/es-ES.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/es.json",
        cache: undefined
      }
    ],
    language: "es",
    dir: "ltr",
    file: undefined
  },
  {
    code: "de",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/de-DE.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/de.json",
        cache: undefined
      }
    ],
    language: "de",
    dir: "ltr",
    file: undefined
  },
  {
    code: "fr",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/fr-FR.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/fr.json",
        cache: undefined
      }
    ],
    language: "fr",
    dir: "ltr",
    file: undefined
  },
  {
    code: "it",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/it-IT.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/it.json",
        cache: undefined
      }
    ],
    language: "it",
    dir: "ltr",
    file: undefined
  },
  {
    code: "he",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/he.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/zod-he.json",
        cache: undefined
      }
    ],
    language: "he",
    dir: "rtl",
    file: undefined
  },
  {
    code: "hu",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/hu-HU.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/hu.json",
        cache: undefined
      }
    ],
    language: "hu",
    dir: "ltr",
    file: undefined
  },
  {
    code: "nl",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/nl.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/zod-nl.json",
        cache: undefined
      }
    ],
    language: "it",
    dir: "ltr",
    file: undefined
  },
  {
    code: "uk-UA",
    files: [
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/uk-UA.json",
        cache: undefined
      },
      {
        path: "/home/runner/work/ronaldo-web/ronaldo-web/i18n/locales/uk-UA.json",
        cache: undefined
      }
    ],
    language: "uk-UA",
    dir: "ltr",
    file: undefined
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/