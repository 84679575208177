<script lang="ts" setup>
const { t } = useI18n();
const shapesStore = useShapesStore();

const canUndo = computed(() => shapesStore.historyState.canUndo);
const canRedo = computed(() => shapesStore.historyState.canRedo);
const onUndoClick = () => shapesStore.historyState.undo();
const onRedoClick = () => shapesStore.historyState.redo();
</script>

<template>
  <TButton
    :tooltip="t('labels.undo')"
    icon="undo"
    :disabled="!canUndo"
    @click="onUndoClick"
  />
  <TButton
    :tooltip="t('labels.redo')"
    icon="redo"
    :disabled="!canRedo"
    @click="onRedoClick"
  />
</template>
