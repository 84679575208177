export const clone = <T>(data: T): T => JSON.parse(JSON.stringify(data));
export const isSame = <T>(a: T, b: T): boolean =>
  JSON.stringify(a) === JSON.stringify(b);

export function areFiltersApplied(
  filters: Record<string, any>,
  enabledKeys?: string[],
): boolean {
  const keysToCheck = enabledKeys
    ? Object.keys(filters).filter(fKey => enabledKeys.includes(fKey))
    : Object.keys(filters);

  return keysToCheck.some(fKey => isFilterActive(filters[fKey]));
}

function isFilterActive(filterValue: any): boolean {
  return Array.isArray(filterValue) ? !!filterValue.length : !!filterValue;
}

export const getObjectsDifference = <T extends object>(obj1: T, obj2: T): Partial<T> => {
  const diff: Partial<T> = {};

  const allKeys = new Set([
    ...Object.keys(obj1 || {}),
    ...Object.keys(obj2 || {}),
  ] as Array<keyof T>);

  for (const key of allKeys) {
    if (key in obj1 && key in obj2) {
      if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        diff[key] = obj2[key];
      }
    }
    else {
      diff[key] = key in obj2 ? (obj2 as any)[key] : undefined;
    }
  }

  return diff;
};
