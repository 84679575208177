<script setup lang="ts">
import type { PxlIconName } from "~/common/components/U/Icon";
import { ShapeStrokeTypeOptions } from "~/telestrations/constants/colors";

const { t } = useI18n();
const shapesStore = useShapesStore();

const options = computed(() => {
  if (shapesStore.selectedShape) {
    return ShapeStrokeTypeOptions[shapesStore.selectedShape.type];
  }

  return [];
});
const selectedStrokeType = computed(() => {
  if (shapesStore.selectedShape) {
    return shapesStore.selectedShape.dash;
  }

  return options.value[0]?.value ?? [0, 0];
});

function onShapeStrokeChange(value: number[]) {
  if (!shapesStore.selectedShape) {
    return;
  }

  shapesStore.updateShape(shapesStore.selectedShape, { dash: value });
}
</script>

<template>
  <template v-if="options.length">
    <USelectMenu
      :model-value="selectedStrokeType"
      :options="options"
      input-class="!border-none !gap-x-2 pl-1"
      list-class="grid grid-cols-3 p-2 gap-2"
      item-class="rounded"
      variant="inline"
      :divider="false"
      :hide-trailing-icon="true"
      @update:model-value="onShapeStrokeChange"
    >
      <template #option="{ option }">
        <UIcon class="size-6 rounded-full" :name="`border-type-${option.label}` as PxlIconName" />
      </template>
      <UIcon v-tooltip="t('labels.change_border_type')" class="size-6" name="line-type" />
    </USelectMenu>
  </template>
</template>
