import getSharedHeaders from "~/common/helpers/get-headers";
import type { components } from "#build/types/nuxt-open-fetch/atlit";
import { formatTelestration } from "~/telestrations/helpers/format-telestration";

/// TODO: Update `useAPI` to use `$atlitFetch` once guys on backend will document the Swagger API for Telestrations.

export function getPlaylistItemTelestrations(
  id: string,
  targetId: string,
  options?: { accessToken?: string; locale?: string },
): Promise<any> {
  return useAPI<{ data: components["schemas"]["TelestrationPlay"] }>(
    `api/v1/playlists/${id}/${targetId}/telestrations`,
    {
      headers: getSharedHeaders(options),
    },
  ).then(response => response.data);
}

export function createPlaylistItemTelestration(
  id: string,
  targetId: string,
  data: components["schemas"]["CreateTelestrationAttr"],
) {
  return useAPI<{ data: components["schemas"]["TelestrationPlay"] }>(
    `api/v1/playlists/${id}/${targetId}/telestrations`,
    {
      headers: getSharedHeaders({ locale: "en" }),
      method: "POST",
      body: data,
    },
  ).then(telestration => formatTelestration(telestration.data));
}

export function updatePlaylistItemTelestration(
  id: string,
  targetId: string,
  telestrationId: string,
  data: components["schemas"]["UpdateTelestrationAttr"],
) {
  return useAPI<{ data: components["schemas"]["TelestrationPlay"] }>(
    `api/v1/playlists/${id}/${targetId}/telestrations/${telestrationId}`,
    {
      headers: getSharedHeaders({ locale: "en" }),
      method: "PATCH",
      body: data,
    },
  ).then(telestration => formatTelestration(telestration.data));
}

export function deletePlaylistItemTelestration(
  id: string,
  targetId: string,
  telestrationId: string,
): Promise<any> {
  return useAPI(
    `api/v1/playlists/${id}/${targetId}/telestrations/${telestrationId}`,
    {
      headers: getSharedHeaders({ locale: "en" }),
      method: "DELETE",
    },
  ).then(() => {});
}
