<script setup lang="ts">
import {
  ShapeFillColors,
  ShapeStrokeColors,
  ShapeStrokeTypeOptions,
  ShapeTextSizeOptions,
} from "~/telestrations/constants/colors";
import type { Shape } from "~/telestrations/types/canvas";

const { t } = useI18n();
const canvasStore = useCanvasStore();
const shapesStore = useShapesStore();

const canSendToTop = computed(() => {
  return (
    shapesStore.selectedShape
    && shapesStore.shapes.length > 1
    && shapesStore.shapes[shapesStore.shapes.length - 1].id
    !== shapesStore.selectedShape.id
  );
});
const canSendToBottom = computed(() => {
  return (
    shapesStore.selectedShape
    && shapesStore.shapes.length > 1
    && shapesStore.shapes[0].id !== shapesStore.selectedShape.id
  );
});

const rootEl = useTemplateRef<HTMLDivElement | null>("rootEl");
const { width: rootElWidth, height: rootElHeight } = useElementSize(rootEl);

const isStrokeSelectAvailable = computed(() => {
  if (!shapesStore.selectedShape) {
    return false;
  }

  return ShapeStrokeColors[shapesStore.selectedShape.type].length > 0;
});
const isFillSelectAvailable = computed(() => {
  if (!shapesStore.selectedShape) {
    return false;
  }

  return ShapeFillColors[shapesStore.selectedShape.type].length > 0;
});
const isBorderTypeSelectAvailable = computed(() => {
  if (!shapesStore.selectedShape) {
    return false;
  }

  return ShapeStrokeTypeOptions[shapesStore.selectedShape.type].length > 0;
});
const isTextSizeSelectAvailable = computed(() => {
  if (!shapesStore.selectedShape) {
    return false;
  }

  return ShapeTextSizeOptions[shapesStore.selectedShape.type].length > 0;
});

const coords = computed(() => {
  return {
    x: shapesStore.transformerPosition?.x ?? 0,
    y: shapesStore.transformerPosition?.y ?? 0,
    scaleX: shapesStore.selectedShape?.scale?.x ?? 1,
    scaleY: shapesStore.selectedShape?.scale?.y ?? 1,
  };
});

const transformerLeftPosition = computed(() => {
  if (!coords.value) {
    return 0;
  }

  const position = Math.round(coords.value.x * coords.value.scaleX);
  const maxXOffset = 16;
  const maxX = canvasStore.canvasSize.width - rootElWidth.value - maxXOffset;

  return Math.min(maxX, Math.max(0, position));
});
const transformerTopPosition = computed(() => {
  if (!coords.value) {
    return 0;
  }

  const topOffset = 60;

  const position = Math.round(coords.value.y * coords.value.scaleY) - topOffset;
  const maxYOffset = 16;
  const maxY = canvasStore.canvasSize.height - rootElHeight.value - maxYOffset;

  return Math.min(maxY, Math.max(0, position));
});

function onClickDelete(shape: Shape) {
  shapesStore.removeShape(shape.id);
}
</script>

<template>
  <div
    v-if="shapesStore.selectedShape"
    ref="rootEl"
    class="absolute flex h-14 gap-x-2 rounded bg-white p-2"
    :style="{
      left: `${transformerLeftPosition}px`,
      top: `${transformerTopPosition}px`,
    }"
  >
    <TSelectStroke v-if="isStrokeSelectAvailable" class="pl-0" />
    <UDivider v-if="isStrokeSelectAvailable" vertical lighter />
    <TSelectFill v-if="isFillSelectAvailable" />
    <UDivider v-if="isFillSelectAvailable" vertical lighter />
    <TSelectBorderType v-if="isBorderTypeSelectAvailable" />
    <UDivider v-if="isBorderTypeSelectAvailable" vertical lighter />
    <TSelectTextSize v-if="isTextSizeSelectAvailable" />
    <UDivider v-if="isTextSizeSelectAvailable" vertical lighter />
    <div class="flex items-center px-3.5">
      <TButton
        :icon="{ name: 'bring-front', class: 'size-5' }"
        variant="link-secondary"
        class="text-black"
        :disabled="!canSendToTop"
        :tooltip="t('labels.bring_to_front')"
        @click="shapesStore.sendShapeToTop(shapesStore.selectedShape.id)"
      />
    </div>
    <UDivider vertical lighter />
    <div class="flex items-center px-3.5">
      <TButton
        :icon="{ name: 'bring-back', class: 'size-5' }"
        variant="link-secondary"
        class="text-black"
        :disabled="!canSendToBottom"
        :tooltip="t('labels.send_to_back')"
        @click="shapesStore.sendShapeToBottom(shapesStore.selectedShape.id)"
      />
    </div>
    <UDivider vertical lighter />
    <div class="flex items-center px-3.5">
      <TButton
        :icon="{ name: 'delete', class: 'size-5' }"
        variant="link-danger"
        :tooltip="t('labels.delete')"
        @click="onClickDelete(shapesStore.selectedShape)"
      />
    </div>
  </div>
</template>
