<script lang="ts" setup>
import { filterTelestrationsTimeIntersection } from "~/telestrations/helpers/filterTelestrationsTimeIntersection";
import { svgStringToBase64Url } from "~/telestrations/helpers/svgStringToBase64Url";

const { t } = useI18n();
const toast = useToast();
const telestrationStore = useTelestrationStore();

const { isLoading: isDisabled, execute: onSave } = useAsyncState(
  async () => {
    const currentPlaylistItem = telestrationStore.currentPlaylistItem;
    const currentPlaylistItemId = telestrationStore.currentPlaylistItem?.id;
    const currentPlaylistId = currentPlaylistItem?.playlistId;
    const currentTelestrations = telestrationStore.currentPlaylistItem?.telestrations || [];

    if (!currentPlaylistId || !currentPlaylistItemId || !currentPlaylistItem) {
      throw new Error("No playlist ID or active playlist item");
    }
    const currentTime = telestrationStore.currentVideoTime;

    const existingTagTimeTelestration = filterTelestrationsTimeIntersection(currentTelestrations, currentTime + currentPlaylistItem.startTime)[0];
    const svgString = useCanvasStore().getCurrentSVGString();

    if (!svgString) {
      if (!existingTagTimeTelestration) {
        throw new Error("No telestration found to delete");
      }

      return deletePlaylistItemTelestration(
        currentPlaylistId,
        currentPlaylistItemId,
        existingTagTimeTelestration.id,
      );
    }

    const base64Result = svgStringToBase64Url(svgString);

    if (existingTagTimeTelestration) {
      return updatePlaylistItemTelestration(
        currentPlaylistId,
        currentPlaylistItemId,
        existingTagTimeTelestration.id,
        { start: currentTime + currentPlaylistItem.startTime, body: base64Result },
      );
    }

    return createPlaylistItemTelestration(
      currentPlaylistId,
      currentPlaylistItemId,
      {
        start: currentPlaylistItem.startTime + currentTime,
        body: base64Result,
      },
    );
  },
  null,
  {
    immediate: false,
    onSuccess: () => {
      toast.success(t("pages.playlists.telestrations.save_success"));
      telestrationStore.exitTelestrationMode();
    },
    onError: err => toast.error(parseErrorMessage(err, { t })),
  },
);
</script>

<template>
  <TButton
    variant="primary"
    :label="t('labels.save')"
    :disabled="isDisabled"
    @click="onSave"
  />
</template>
