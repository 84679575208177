import payload_plugin_VvvAkBZ_VOuFjWj7DBj0dOJXfe4ab4QfX6erGHItx8I from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.36.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_InrVFj2PPyKKzeENSrTt7ke5fbqCsTBrKT2GouRdeYw from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._31afcc742565f46b46e95e488742d3a0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nb_QCpb__bq5BbVoKSToLH7XJcL942_MhiZWtfKOuO0 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._31afcc742565f46b46e95e488742d3a0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XI7csEeGBLmBqP7hyI1jYiYFZz4AN1ugiZm_FV4Oyio from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._31afcc742565f46b46e95e488742d3a0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rVFVeDj1xe9D7pmYSFR_7XMOQk38esBvzIH_cFaTUVI from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._31afcc742565f46b46e95e488742d3a0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_B6tx7s_IWxVjzqMHelRsrkZOaGNJQKoRdOwMnXclDaY from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._31afcc742565f46b46e95e488742d3a0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client__bQqXGE7c9UtTe8wssq726gf6Pjn1ruZi4tJFwlcWTY from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._31afcc742565f46b46e95e488742d3a0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Gqpp9BIvs3AFdddxYKM2RszBids7A5VOABx0sKiYY68 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.36.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_xIIo6T2zF3oyYn_PFl2LSkjfnFZ5cC_yHeIG14x6Gvs from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._31afcc742565f46b46e95e488742d3a0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KxFiT_EOJpsW1cU_3BKhMRHQ66kRM_gtELZHkbJ2nug from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.36.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_Q82PGvcygJOrecCkBGfEXKuR1sIETo475cqmlX5Z_8M from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_884dacd093a5328c1ae5a894922f2f65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_6NWWSCyrTVD3_hClx37AwZwubLcG2gSbv8WCKgJFWfo from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_884dacd093a5328c1ae5a894922f2f65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_5miEiQHqM_U1VL1e3MaRPN1BQ3MBck1pRFeoyeSTFAw from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_884dacd093a5328c1ae5a894922f2f65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_iIjw6U4054CXPakJrlOMbwLDA_RAtC1Y_8TjnLqVuwA from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/plugin.js";
import plugin_client_KoYRQtJuzxoXnHGQ2woLdhTP7GR5uYvaiqLq7_TKqnU from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.36.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_KnPiXuwPJd3CJQ9W9Evay9oeP4gCOl_M_b4o7vcONFQ from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-open-fetch@0.2.2_magicast@0.3.5_rollup@4.36.0/node_modules/nuxt-open-fetch/dist/runtime/plugin.mjs";
import konva_client_4Yp6TgmVvp29e9WG4hyScSYiClskEdtETSDNekjK0b0 from "/home/runner/work/ronaldo-web/ronaldo-web/app/telestrations/plugins/konva.client.ts";
import firebase_client_fBeI1p_eszNDPpTz2RvzN0iRzs05mJgyUbXp5CNK8Rg from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/plugins/firebase.client.ts";
import middleware_global_f3_wp13giM1lz7bWtyHFQAK7xZ6GrtZJkX9OcuMpvQ4 from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/plugins/middleware.global.ts";
import auto_animate_client_OoX1bbRFfGzoplLwq9yT7s4PQ6UCaXjUr280xijO7D4 from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/auto-animate.client.ts";
import datadog_client_eccJXs_ApWON3IwA4lxNFwcNrr0pG0paiYoBsYtUrVI from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/datadog.client.ts";
import floating_vue__wekiCMwPmPywGdgKsXmMB_xM8ewX1Swnhf9eCglgUU from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/floating-vue.ts";
import google_analytics_client_GrBxW6ap1cdqBVxvODouTelKU7C1c4uQur_9TBhPCYA from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/google-analytics.client.ts";
import modals_gID_HIHj50tK5KHAANS9atOYDti1KilEDW5PuzOUxDE from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/modals.ts";
import openFetch_ywB5hZimVwXHfNga77Cs8PGTJea3Ozkp5Cj2MZah_ec from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/openFetch.ts";
import ssg_detect_CDjZhgDeo1OTnVkIraKbqM_3njMWChhWNGVQ_Sw8WTo from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_884dacd093a5328c1ae5a894922f2f65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_VvvAkBZ_VOuFjWj7DBj0dOJXfe4ab4QfX6erGHItx8I,
  revive_payload_client_InrVFj2PPyKKzeENSrTt7ke5fbqCsTBrKT2GouRdeYw,
  unhead_nb_QCpb__bq5BbVoKSToLH7XJcL942_MhiZWtfKOuO0,
  router_XI7csEeGBLmBqP7hyI1jYiYFZz4AN1ugiZm_FV4Oyio,
  payload_client_rVFVeDj1xe9D7pmYSFR_7XMOQk38esBvzIH_cFaTUVI,
  navigation_repaint_client_B6tx7s_IWxVjzqMHelRsrkZOaGNJQKoRdOwMnXclDaY,
  chunk_reload_client__bQqXGE7c9UtTe8wssq726gf6Pjn1ruZi4tJFwlcWTY,
  plugin_vue3_Gqpp9BIvs3AFdddxYKM2RszBids7A5VOABx0sKiYY68,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_xIIo6T2zF3oyYn_PFl2LSkjfnFZ5cC_yHeIG14x6Gvs,
  plugin_KxFiT_EOJpsW1cU_3BKhMRHQ66kRM_gtELZHkbJ2nug,
  switch_locale_path_ssr_Q82PGvcygJOrecCkBGfEXKuR1sIETo475cqmlX5Z_8M,
  route_locale_detect_6NWWSCyrTVD3_hClx37AwZwubLcG2gSbv8WCKgJFWfo,
  i18n_5miEiQHqM_U1VL1e3MaRPN1BQ3MBck1pRFeoyeSTFAw,
  plugin_iIjw6U4054CXPakJrlOMbwLDA_RAtC1Y_8TjnLqVuwA,
  plugin_client_KoYRQtJuzxoXnHGQ2woLdhTP7GR5uYvaiqLq7_TKqnU,
  plugin_KnPiXuwPJd3CJQ9W9Evay9oeP4gCOl_M_b4o7vcONFQ,
  konva_client_4Yp6TgmVvp29e9WG4hyScSYiClskEdtETSDNekjK0b0,
  firebase_client_fBeI1p_eszNDPpTz2RvzN0iRzs05mJgyUbXp5CNK8Rg,
  middleware_global_f3_wp13giM1lz7bWtyHFQAK7xZ6GrtZJkX9OcuMpvQ4,
  auto_animate_client_OoX1bbRFfGzoplLwq9yT7s4PQ6UCaXjUr280xijO7D4,
  datadog_client_eccJXs_ApWON3IwA4lxNFwcNrr0pG0paiYoBsYtUrVI,
  floating_vue__wekiCMwPmPywGdgKsXmMB_xM8ewX1Swnhf9eCglgUU,
  google_analytics_client_GrBxW6ap1cdqBVxvODouTelKU7C1c4uQur_9TBhPCYA,
  modals_gID_HIHj50tK5KHAANS9atOYDti1KilEDW5PuzOUxDE,
  openFetch_ywB5hZimVwXHfNga77Cs8PGTJea3Ozkp5Cj2MZah_ec,
  ssg_detect_CDjZhgDeo1OTnVkIraKbqM_3njMWChhWNGVQ_Sw8WTo
]