import { defineStore } from "pinia";

import { base64UrlToSVGString } from "../helpers/base64UrlToSVGString";
import { getShapesFromSVGString } from "../helpers/getShapesFromSVGString";
import type { IPlaylistItem } from "~/types";
import { filterTelestrationsTimeIntersection } from "~/telestrations/helpers/filterTelestrationsTimeIntersection";

export enum TelestrationMode {
  PREVIEW = "preview",
  EDIT = "edit",
  NONE = "none",
}

export const useTelestrationStore = defineStore("telestration", () => {
  const shapesStore = useShapesStore();
  const currentPlaylistItem = ref<IPlaylistItem | null>(null);
  const currentVideoTime = ref(0);
  const telestrationMode = ref<TelestrationMode>(TelestrationMode.NONE);
  const telestrationButtonShown = ref<boolean>(
    isFeatureEnabled("enable_telestration_mode"),
  );

  function openPreviewForPlaylistItem(item: IPlaylistItem, currentTime: number) {
    return enterTelestrationModeForPlaylistItem(TelestrationMode.PREVIEW, item, currentTime);
  }

  function openEditForPlaylistItem(item: IPlaylistItem, currentTime: number) {
    return enterTelestrationModeForPlaylistItem(TelestrationMode.EDIT, item, currentTime);
  }

  function openEditorForPlaylistItem(item: IPlaylistItem, currentTime: number) {
    resetStore();

    currentVideoTime.value = currentTime;
    currentPlaylistItem.value = item;
    telestrationMode.value = TelestrationMode.EDIT;
  }

  function enterTelestrationModeForPlaylistItem(
    mode: TelestrationMode,
    item: IPlaylistItem,
    currentTime?: number,
  ): void {
    resetStore();
    telestrationMode.value = mode;
    currentVideoTime.value = currentTime ?? item.startTime;
    currentPlaylistItem.value = item;

    renderTelestrationsAsShapesFromPlaylistItem(item);
  }

  function setupTelestrationCanvasOnContainer(container: HTMLDivElement) {
    useCanvasStore().containerRef = container;
  }

  function resetStore() {
    shapesStore.$reset();
    useToolsStore().$reset();
    currentPlaylistItem.value = null;
    currentVideoTime.value = 0;
    telestrationMode.value = TelestrationMode.NONE;
  }

  function renderTelestrationsAsShapesFromPlaylistItem(item: IPlaylistItem) {
    const currentTime = currentVideoTime.value + item.startTime;
    const currentTelestrations = filterTelestrationsTimeIntersection(item.telestrations, currentTime);

    shapesStore.historyState.pause();

    currentTelestrations.forEach((t) => {
      const decodedSvg = base64UrlToSVGString(t?.body);

      if (!decodedSvg) {
        return;
      }

      const shapes = getShapesFromSVGString(decodedSvg);

      shapes.forEach(shape => shapesStore.addShape(shape));
    });

    useToolsStore().resetToSelectionTool();

    // TODO: Remove use of setTimeout - this feels dumb
    setTimeout(() => shapesStore.historyState.resume(), 100);
  }

  return {
    telestrationMode,
    telestrationButtonShown,
    currentVideoTime,
    currentPlaylistItem,

    openPreviewForPlaylistItem,
    openEditForPlaylistItem,
    openEditorForPlaylistItem,
    exitTelestrationMode: resetStore,
    setupTelestrationCanvasOnContainer,
  };
});
