import type Konva from "konva";

export enum ShapeType {
  Line = "line",
  Rectangle = "rectangle",
  Circle = "circle",
  Arrow = "arrow",
  Text = "text",
}

export type CommonShapeConfig = {
  id: string;
  layerIndex: number;
  type: ShapeType;
  startX: number;
  startY: number;
};

export type Shape = Konva.ShapeConfig & CommonShapeConfig;
