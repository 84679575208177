<script setup lang="ts">
import type Konva from "konva";
import { ShapeTextSizeOptions } from "~/telestrations/constants/colors";

const { t } = useI18n();
const shapesStore = useShapesStore();

const options = computed(() => {
  if (shapesStore.selectedShape) {
    return ShapeTextSizeOptions[shapesStore.selectedShape.type];
  }

  return [];
});
const selectedTextSize = computed(() => {
  if (shapesStore.selectedShape) {
    const currentFontSize = (shapesStore.selectedShape as unknown as Konva.Text).fontSize;

    if (typeof currentFontSize === "number") {
      return currentFontSize;
    }

    return null;
  }

  return options.value[0]?.value;
});

function onChange(value: number[]) {
  if (!shapesStore.selectedShape) {
    return;
  }

  shapesStore.updateShape(shapesStore.selectedShape, { fontSize: value });
}
</script>

<template>
  <template v-if="options.length">
    <USelectMenu
      :model-value="selectedTextSize"
      :options="options"
      input-class="!border-none !gap-x-2 pl-1"
      list-class="grid grid-cols-3 p-2 gap-2"
      item-class="rounded aria-selected:text-blue-500"
      variant="inline"
      size="sm"
      list-item-size="auto"
      :divider="false"
      :hide-trailing-icon="true"
      @update:model-value="onChange"
    >
      <UIcon v-tooltip="t('labels.change_text_size')" class="size-6" name="text-size" />
    </USelectMenu>
  </template>
</template>
