<script lang="ts" setup>
import type { Shape } from "@/telestrations/types/canvas";

const props = defineProps<{
  shape: Shape;
}>();

const shapesStore = useShapesStore();

function handleDragEnd(e: Event) {
  shapesStore.onShapeDragEnd(e, props.shape);
}

function handleShapeClick() {
  shapesStore.selectShape(props.shape);
}
function handleTransformEnd(e: Event) {
  shapesStore.onShapeTransformEnd(e);
}
</script>

<template>
  <v-rect
    :config="props.shape"
    @dragstart="shapesStore.onShapeDragStart"
    @dragmove="shapesStore.onShapeDragMove"
    @dragend="handleDragEnd"
    @click="handleShapeClick"
    @transformend="handleTransformEnd"
  />
</template>
