import formatAthlete from "~/athletes/helpers/format-athlete";
import formatTeam from "~/teams/helpers/format-team";
import type { IAthlete, ITeam } from "~/types";
import type { components } from "#build/types/nuxt-open-fetch/atlit";

interface IRequestParams {
  q: string;
  limit: number;
  offset: number;
}

export const getCachedAthletesList = useMemoize((query: Partial<IRequestParams>) => getAthletesList(query));

export function getAthletesList(query: Partial<IRequestParams>): Promise<{ data: IAthlete[]; total: number }> {
  return $atlitFetch("/v1/athletes", {
    query: {
      q: query.q,
      limit: query.limit,
      offset: query.offset,
      // @ts-expect-error TODO: Look for better type checking
      relationships: "club,membership,roster",
    },
  }).then(res => ({
    data: res.data.map(athlete => formatAthlete(athlete)),
    total: res.meta?.total || res.data.length,
  }));
}

export const getCachedAthleteById = useMemoize((athleteId: string) => getAthleteById(athleteId));

export function getAthleteById(athleteId: string): Promise<IAthlete | null> {
  return $atlitFetch("/v1/athletes/{ID}", {
    path: {
      ID: athleteId,
    },
  }).then(res => formatAthlete(res.data));
}
export function getAthleteTeamsByAthleteId(athleteId: string, options: { season: number }): Promise<ITeam[]> {
  return $atlitFetch("/v1/athletes/{ID}/teams", {
    path: {
      ID: athleteId,
    },
    query: {
      season: String(options.season),
    },
  }).then(res => res.data.map(c => formatTeam(c)));
}
export function deleteAthleteById(athleteId: string): Promise<void> {
  return $atlitFetch("/v1/athletes/{ID}", {
    // @ts-expect-error TODO: by BE
    method: "DELETE",
    path: {
      ID: athleteId,
    },
  }).then(() => {
    getCachedAthleteById.delete(athleteId);
    getCachedAthletesList.clear();
  });
}

export function deleteAthleteRosterByRosterId(athleteRosterId: string) {
  return $atlitFetch("/v1/clubs/rosters/athletes/{ID}", {
    method: "DELETE",
    path: { ID: athleteRosterId },
  });
}

export function postMergeAthletes(
  mainAthleteId: string,
  secondAthleteId: string,
  profile: components["schemas"]["MergeAthleteAttr"]["profile"],
) {
  return $atlitFetch("/v1/athletes/merge", {
    method: "POST",
    body: {
      athleteIds: [mainAthleteId, secondAthleteId],
      profile,
    },
  });
}

/**
 * Returns the email address registered to the Athlete in the given Club.
 * @param athleteId
 * @param clubId
 * @returns
 */
export async function getAthleteEmail(athleteId: string, clubId: string): Promise<string | undefined> {
  const res = await $atlitFetch("/v1/clubs/{ID}/rosters/athletes", {
    method: "GET",
    path: {
      ID: clubId,
    },
    query: {
      athleteId: athleteId,
      relationships: "membership" as "athlete",
    },
  });

  return res.data?.[0].relationships?.membership?.attributes?.profile?.email;
}

/**
 * Returns true if the email address is already in use by an Athlete.
 * @param email
 * @returns
 */
export function isAthleteEmailInUse(email: string): Promise<boolean> {
  return $atlitFetch("/v1/users/email", {
    method: "POST",
    query: {
      relationships: "athlete" as const,
    },
    body: {
      email: email,
    },
  })
    .then(() => true)
    .catch(() => false);
}

/**
 * Returns the Jersey number for the given Athete in the given Club and Season. Returns undefined if no match was found.
 * @param athleteId
 * @param clubId
 * @param season
 * @returns string | undefined
 */
export async function getAthleteSeasonJersey(athleteId: string, clubId: string, season: number): Promise<string | undefined> {
  const res = await $atlitFetch("/v1/clubs/{ID}/rosters/athletes", {
    path: {
      ID: clubId,
    },
    query: {
      season: season,
      athleteId,
    },
  });

  return res.data[0]?.attributes?.jersey;
}

/**
 * Returns if the Jersey number is occupied by an Athlete in the given Club and Season.
 * @param clubId
 * @param season
 * @param jersey
 * @returns
 */
export async function isAthleteJerseyOccupied(clubId: string, season: number, jersey: string): Promise<boolean> {
  const res = await $atlitFetch("/v1/clubs/{ID}/rosters/athletes", {
    path: {
      ID: clubId!,
    },
    query: {
      season,
      // @ts-expect-error Wait for swagger update
      jersey,
    },
  });

  return (res.data || []).length > 0;
}
