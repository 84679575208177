<script lang="ts" setup>
import { twJoin } from "tailwind-merge";
import { inputSizes, inputVariants } from "./";
import type { PxlIcon } from "@/common/components/U/Icon";
import { UIconLoading } from "#components";
import { iconBind } from "@/common/components/U/Icon";

// TODO: Move into separate file when such feature will be supported
interface ICommonInputProps {
  name?: string;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  hasFocus?: boolean;
  icon?: PxlIcon;
  leadingIcon?: PxlIcon;
  trailingIcon?: PxlIcon;
  trailing?: boolean;
  leading?: boolean;
}

const props = withDefaults(
  defineProps<
    ICommonInputProps & {
      size?: keyof typeof inputSizes;
      variant?: keyof typeof inputVariants;
    }
  >(),
  {
    size: "md",
    variant: "default",
    icon: null,
    leadingIcon: null,
    trailingIcon: null,
  },
);

const { formGroup } = useFormGroup();
const hasError = computed(() => props.hasError || !!formGroup?.error?.value);
const errorMessage = computed(() => hasError.value && formGroup?.errorType === "tooltip-in" && formGroup?.error?.value);
const isDisabled = computed(() => props.disabled || !!formGroup?.disabled?.value);

const isTrailing = computed(
  () => (props.icon && props.trailing) || (props.loading && props.trailing) || (hasError.value && formGroup?.errorType !== "tooltip-out") || !!props.trailingIcon,
);
const isLeading = computed(
  () =>
    (props.icon && props.leading)
    || (props.icon && !props.trailing)
    || (props.loading && !props.trailing)
    || !!props.leadingIcon,
);

const leadingIconName = computed(() => props.leadingIcon || props.icon);
const trailingIconName = computed<PxlIcon>(() => {
  if (hasError.value && formGroup?.errorType !== "tooltip-out") return { name: "warning", class: "text-red-500 dark:text-red-500" };

  return props.trailingIcon || props.icon;
});

const inputClass = computed(() => {
  const variant = inputVariants[props.variant] || inputVariants.default;
  const size = inputSizes[formGroup?.size?.value ?? props.size] || inputSizes.md;
  const error = hasError.value ? "u-input-error" : null;
  const focused = props.hasFocus ? "u-input-focused" : null;
  const disabled = isDisabled.value ? "u-input-disabled" : null;

  return twJoin([variant, size, error, focused, disabled]);
});
</script>

<template>
  <div
    class="u-input"
    :class="inputClass"
  >
    <span
      v-if="isLeading || $slots.leading"
      class="u-input-leading-wrapper"
    >
      <slot
        name="leading"
        :disabled="isDisabled"
        :loading="loading"
      >
        <UIconLoading v-if="props.loading && isLeading" />
        <UIcon
          v-else-if="leadingIconName"
          v-bind="iconBind(leadingIconName)"
        />
      </slot>
    </span>

    <slot />

    <span
      v-if="isTrailing || $slots.trailing"
      class="u-input-trailing-wrapper"
    >
      <slot
        name="trailing"
        :disabled="isDisabled"
        :loading="loading"
      >
        <UIconLoading v-if="props.loading && !isLeading" />
        <UIcon
          v-else-if="trailingIconName"
          v-tooltip="errorMessage"
          v-bind="iconBind(trailingIconName)"
        />
      </slot>
    </span>
  </div>
</template>
