import Konva from "konva";
import { getShapeConfig } from "~/telestrations/constants/shapes";
import { ShapeType, type Shape } from "~/telestrations/types/canvas";

interface ISVGTransform { translateX: number; translateY: number; rotate: number; scaleX: number; scaleY: number };

/**
 * Parses an SVG string and extracts shape configurations into an array of `Shape` objects.
 *
 * This function supports various SVG elements such as `circle`, `rect`, `text`, `polyline`,
 * `polygon`, `line`, and `path`. Each element is converted into a corresponding `Shape`
 * configuration object with its attributes and transformations applied.
 *
 * @param svgString - The SVG string to parse and extract shapes from.
 * @returns An array of `Shape` objects representing the parsed SVG elements.
 *
 * @remarks
 * - The function uses a `DOMParser` to parse the SVG string into an XML document.
 * - Unsupported SVG elements are logged as warnings in the console.
 * - Attributes such as `fill`, `stroke`, `stroke-width`, `transform`, and others are
 *   extracted and applied to the resulting shape configurations.
 * - Random IDs are generated for each shape, and the layer index is determined by the
 *   order of elements in the SVG string.
 *
 * @throws Will throw an error if the SVG string is invalid or cannot be parsed.
 *
 * @example
 * ```typescript
 * const svgString = `
 *   <svg>
 *     <circle cx="50" cy="50" r="40" fill="red" />
 *     <rect x="10" y="10" width="30" height="30" fill="blue" />
 *   </svg>
 * `;
 * const shapes = getShapesFromSVGString(svgString);
 * console.log(shapes);
 * ```
 */
export function getShapesFromSVGString(svgString: string): Shape[] {
  const shapes: Shape[] = [];
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
  const svgElements = svgDoc.querySelectorAll("circle, rect, text, polyline, polygon, line, path");

  svgElements.forEach((svgElement) => {
    if (!svgElement) return;

    const tagName = svgElement.tagName.toLowerCase();
    const id = String(Math.random());
    const layerIndex = shapes.length;

    const transform = parseSvgTransform(svgElement.getAttribute("transform"));
    const translateX = transform.translateX || 0;
    const translateY = transform.translateY || 0;
    const scaleX = transform.scaleX || 1;
    const scaleY = transform.scaleY || 1;
    const rotate = transform.rotate || 0;

    const fill = svgElement.getAttribute("fill") || Konva.Util.getRandomColor();
    const rotation = parseFloat(svgElement.getAttribute("rotation") || "0");
    const stroke = svgElement.getAttribute("stroke") || "black";
    const strokeWidth = parseFloat(svgElement.getAttribute("stroke-width") || "1");
    const dash = svgElement.getAttribute("stroke-dasharray")?.split(",").map(d => parseFloat(d) || 0) || [];

    switch (tagName) {
      case "circle": {
        const cx = parseFloat(svgElement.getAttribute("cx") || "0");
        const cy = parseFloat(svgElement.getAttribute("cy") || "0");
        const radius = parseFloat(svgElement.getAttribute("r") || "0");

        if (isNaN(cx) || isNaN(cy) || isNaN(radius)) {
          console.warn(`Invalid attributes for circle element: ${svgElement.outerHTML}`);
          return;
        }

        shapes.push(getShapeConfig({
          id,
          type: ShapeType.Circle,
          startX: cx,
          startY: cy,
          layerIndex,
        }, {
          strokeWidth,
          stroke,
          x: cx,
          y: cy,
          radius,
          fill,
          scaleX,
          scaleY,
          dash,
        }));
        break;
      }
      case "rect": {
        const x = parseFloat(svgElement.getAttribute("x") || "0");
        const y = parseFloat(svgElement.getAttribute("y") || "0");
        const width = parseFloat(svgElement.getAttribute("width") || "0");
        const height = parseFloat(svgElement.getAttribute("height") || "0");

        if (isNaN(x) || isNaN(y) || isNaN(width) || isNaN(height)) {
          console.warn(`Invalid attributes for rect element: ${svgElement.outerHTML}`);
          return;
        }

        shapes.push(getShapeConfig({
          id,
          type: ShapeType.Rectangle,
          startX: x,
          startY: y,
          layerIndex,
        }, {
          width,
          height,
          strokeWidth,
          stroke,
          x,
          y,
          fill,
          rotation: rotation || rotate,
          scaleX,
          scaleY,
          dash,
        }));
        break;
      }
      case "text": {
        const x = parseFloat(svgElement.getAttribute("x") || "0");
        const y = parseFloat(svgElement.getAttribute("y") || "0");
        const fontSize = parseFloat(svgElement.getAttribute("font-size") || "12");
        const fontFamily = svgElement.getAttribute("font-family") || "Arial";
        const align = svgElement.getAttribute("text-anchor") || "start";
        const textContent = svgElement.textContent || "";

        if (isNaN(x) || isNaN(y) || isNaN(fontSize)) {
          console.warn(`Invalid attributes for text element: ${svgElement.outerHTML}`);
          return;
        }

        shapes.push(getShapeConfig({
          id,
          type: ShapeType.Text,
          startX: x,
          startY: y,
          layerIndex,
        }, {
          strokeWidth,
          stroke,
          x,
          y,
          fill,
          text: textContent,
          fontSize,
          fontFamily,
          align,
          rotation: rotation || rotate,
          scaleX,
          scaleY,
        }));
        break;
      }
      case "polyline":
      case "polygon": {
        const pointsString = svgElement.getAttribute("points");
        if (!pointsString) {
          console.warn(`Missing points attribute for ${tagName} element: ${svgElement.outerHTML}`);
          return;
        }

        const points = pointsString.split(" ").map((point) => {
          const coords = point.split(",");
          return coords.length === 2 ? [parseFloat(coords[0]), parseFloat(coords[1])] : null;
        }).flat();

        if (points.includes(null)) {
          console.warn(`Invalid points attribute for ${tagName} element: ${svgElement.outerHTML}`);
          return;
        }

        shapes.push(addLine(svgElement, layerIndex, translateX, translateY, strokeWidth, stroke, { translateX, translateY, scaleX, scaleY, rotate }));
        break;
      }
      case "path": {
        const d = svgElement.getAttribute("d");
        if (!d) {
          console.warn(`Missing d attribute for path element: ${svgElement.outerHTML}`);
          return;
        }

        const points = d.split(/[ML,]/).filter(Boolean).map(parseFloat);
        if (points.some(isNaN)) {
          console.warn(`Invalid d attribute for path element: ${svgElement.outerHTML}`);
          return;
        }

        shapes.push(getShapeConfig({
          id,
          layerIndex,
          type: ShapeType.Arrow,
          startX: translateX,
          startY: translateY,
        }, {
          points,
          x: translateX,
          y: translateY,
          scale: { x: scaleX, y: scaleY },
          rotation: rotation || 0,
          stroke,
          dash,
        }));
        break;
      }
      default:
        console.warn(`Unsupported SVG element: ${tagName}`);
    }
  });

  return shapes;
}

function parseSvgTransform(transformString: string | null): ISVGTransform {
  const transformations: ISVGTransform = {} as ISVGTransform;

  if (!transformString) {
    return transformations;
  }

  // Extract translate
  const translateMatch = transformString.match(/translate\((-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\)/);
  if (translateMatch) {
    transformations.translateX = parseFloat(translateMatch[1]);
    transformations.translateY = parseFloat(translateMatch[3]);
  }

  // Extract rotate
  const rotateMatch = transformString.match(/rotate\((-?\d+(\.\d+)?)\)/);
  if (rotateMatch) {
    transformations.rotate = parseFloat(rotateMatch[1]);
  }

  // Extract scale
  const scaleMatch = transformString.match(/scale\((-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\)/);
  if (scaleMatch) {
    transformations.scaleX = parseFloat(scaleMatch[1]);
    transformations.scaleY = parseFloat(scaleMatch[3]);
  }
  else {
    const singleScaleMatch = transformString.match(/scale\((-?\d+(\.\d+)?)\)/);
    if (singleScaleMatch) {
      transformations.scaleX = parseFloat(singleScaleMatch[1]);
      transformations.scaleY = parseFloat(singleScaleMatch[1]);
    }
  }

  return transformations;
}

function addLine(
  svgElement: Element, layerIndex: number, startX: number, startY: number, strokeWidth: number, stroke: string,
  { translateX, translateY, scaleX, scaleY, rotate }: ISVGTransform,
): Shape {
  const pointsString = svgElement.getAttribute("points")!;
  const points = pointsString.split(" ").map((point: any) => {
    const coords = point.split(",");
    return [parseFloat(coords[0]), parseFloat(coords[1])];
  }).flat();
  const shape = getShapeConfig({
    id: svgElement.getAttribute("id")!,
    layerIndex,
    type: ShapeType.Line,
    startX,
    startY,
  }, {
    x: translateX,
    y: translateY,
    points,
    scale: { x: scaleX || 1, y: scaleY || 1 },
    rotation: rotate || 0,
    strokeWidth,
    stroke,
  });
  return shape;
}
