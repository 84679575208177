import type Konva from "konva";
import { ShapeType } from "../types/canvas";

const common = ["#FFFF00", "#E8332B", "#0000FF", "#008002"];

type KonvaColorProps = Pick<Konva.ShapeConfig, "stroke" | "fill">;

export const InitialShapeColor: Record<ShapeType, KonvaColorProps> = {
  [ShapeType.Rectangle]: {
    stroke: "#D22018",
    fill: "transparent",
  },
  [ShapeType.Circle]: {
    stroke: "#D22018",
    fill: "transparent",
  },
  [ShapeType.Line]: {
    stroke: "red",
  },
  [ShapeType.Arrow]: {
    stroke: "yellow",
  },
  [ShapeType.Text]: {
    fill: "#000",
  },
};

export const ShapeStrokeColors: Record<ShapeType, string[]> = {
  [ShapeType.Line]: [...common],
  [ShapeType.Rectangle]: [...common],
  [ShapeType.Circle]: [...common],
  [ShapeType.Arrow]: [...common],
  [ShapeType.Text]: [...common],
};
export const ShapeFillColors: Record<ShapeType, string[]> = {
  [ShapeType.Line]: [],
  [ShapeType.Rectangle]: [...common, "transparent"],
  [ShapeType.Circle]: [...common, "transparent"],
  [ShapeType.Arrow]: [],
  [ShapeType.Text]: [...common, "transparent"],
};

const strokeOptions = [
  { label: "solid", value: [0, 0] },
  { label: "dashed", value: [10, 5] },
  { label: "dots", value: [5, 10] },
];

export const ShapeStrokeTypeOptions: Record<ShapeType, typeof strokeOptions> = {
  [ShapeType.Line]: [],
  [ShapeType.Rectangle]: strokeOptions,
  [ShapeType.Circle]: strokeOptions,
  [ShapeType.Arrow]: strokeOptions,
  [ShapeType.Text]: [],
};

const textSizeOptions = [
  { label: "Small", value: 10 },
  { label: "Medium", value: 20 },
  { label: "Large", value: 30 },
];

export const ShapeTextSizeOptions: Record<ShapeType, typeof textSizeOptions> = {
  [ShapeType.Line]: [],
  [ShapeType.Rectangle]: [],
  [ShapeType.Circle]: [],
  [ShapeType.Arrow]: [],
  [ShapeType.Text]: textSizeOptions,
};
