import { defineStore } from "pinia";
import { ShapeType } from "@/telestrations/types/canvas";

export type Tool = "selection" | ShapeType;

// Store for managing tools
export const useToolsStore = defineStore("tools", {
  state: () => ({
    availableTools: ["selection", ShapeType.Line, ShapeType.Circle, ShapeType.Rectangle, ShapeType.Arrow, ShapeType.Text] as Tool[],
    selectedTool: "selection" as Tool,
  }),
  actions: {
    selectTool(tool: Tool) {
      if (!this.availableTools.includes(tool)) {
        console.warn(`Tool ${tool} is not available.`);
        this.resetToSelectionTool();
        return;
      }

      this.selectedTool = tool;
    },
    resetToSelectionTool() {
      this.selectedTool = "selection";
    },
  },
  getters: {
    isSelectionMode: (state) => {
      return state.selectedTool === "selection";
    },
  },
});
