import { getUnixTime, startOfDay, endOfDay } from "date-fns";
import formatEvent, { formatMBEEvent } from "~/videos/helpers/format-event";
import type {
  IAthleteHighlight,
  IBasicVideo,
  IClip,
  IEvent,
  IGameHighlight,
  IResponseEvent,
  IResponseEvents,
  TSportType,
  IBreakdownType,
  IEventStatus,
  IResponseMBEEvent,
  IMbeEvent,
  ITeam,
  ReplayEvent,
} from "~/types";
import { EVENT_STATUS } from "~/constants";
import type { components } from "#build/types/nuxt-open-fetch/atlit";
import formatTeam from "~/teams/helpers/format-team";
import type { IPixellotPlayerSourceType } from "~~/modules/pixellot-sdk";

export interface IRequestPaginateParams {
  limit: number;
  offset: number;
  sort?: "startDateTime" | "endDateTime";
}

export type TEventUrls = components["schemas"]["EventAttr"]["urls"];
export type TEventVideos = components["schemas"]["EventAttr"]["videos"];

// temporary type until "vod" is added to the API status enum
export type TCreateEventPayload = Omit<components["schemas"]["EventAttr"], "status"> & {
  status: components["schemas"]["EventAttr"]["status"] | "vod";
  logging: {
    excludeFromStats: boolean;
    logAfterUpload: boolean;
    externalStatsLink?: string;
  };
  external?: {
    replay: number;
  };
};

export interface IRequestEventParams extends IRequestPaginateParams {
  q: string;
  status: IEventStatus;
  clubId: string;
  teamId: string;
  orgId: string;
  leagueId: string;
  athleteId: string;
  startDate: number;
  endDate: number;
  sportType: TSportType;
  relationships: string;
  season: number | number[];
  type: string | string[];
  id: string;
  with: string;
}

export interface IRequestReplayEventsParams {
  clubId: string;
  season: number;
  seasonType: "single-year" | "cross-year";
  name?: string;
  offset: number;
  limit: number;
}

export const getCachedEventsList = useMemoize(
  (query: Partial<IRequestEventParams>, leagueId?: string): Promise<{ data: IEvent[]; total: number }> => {
    return getEventsList(query, leagueId);
  },
);

export async function getEventsList(
  query: Partial<IRequestEventParams>,
  leagueId?: string,
): Promise<{ data: IEvent[]; total: number }> {
  const appConfig = useAppConfig();
  const location = await useLocation();
  const isUS = location.country === "US";

  if (query.startDate) query.startDate = getUnixTime(startOfDay(new Date(query.startDate).getTime() * 1000));

  if (query.endDate) query.endDate = getUnixTime(endOfDay(new Date(query.endDate).getTime() * 1000));

  if (query.status === EVENT_STATUS.UPCOMING) {
    // @ts-expect-error This override is required since on BE upcoming events have status upcoming live
    query.status = "upcoming_live";
  }

  if (appConfig.useMockData) {
    return $fetch<IResponseEvents>("/mocks/data/events.json").then(res => ({
      data: res.data
        .map(i => formatEvent(i, { isUS }))
        .filter((item) => {
          if (query.status) return query.status === item.status;
          return false;
        }),
      total: res.meta.total,
    }));
  }

  if (leagueId)
    return useAPI<IResponseEvents>(`/api/v1/leagues/${leagueId}/events`, { query }).then(res => ({
      data: res.data.map(i => formatEvent(i, { leagueId, isUS })),
      total: res.meta.total,
    }));

  return useAPI<IResponseEvents>("/api/v1/events", { query }).then(res => ({
    data: res.data.map(i => formatEvent(i, { isUS })),
    total: res.meta.total,
  }));
}

export async function getMbeEventsListByTeamId(
  teamId: string,
  query: Partial<
    Pick<IRequestEventParams, "startDate" | "endDate" | "offset" | "limit" | "id"> & {
      name: string;
    }
  >,
): Promise<{ data: IMbeEvent[]; total: number }> {
  if (query.startDate) query.startDate = getUnixTime(startOfDay(new Date(query.startDate).getTime() * 1000));

  if (query.endDate) query.endDate = getUnixTime(endOfDay(new Date(query.endDate).getTime() * 1000));

  return useAPI<{ data: IResponseMBEEvent[] }>(`/api/v1/external/clubs/${teamId}/events`, { query }).then(res => ({
    data: res.data.map(i => formatMBEEvent(i)),
    total: res.data.length,
  }));
}

export async function getMbeEventById(
  mbeEventId: string,
): Promise<{ data: IMbeEvent; teamsMap: Record<string, ITeam> }> {
  return $atlitFetch("/v1/external/events/{ID}", {
    path: { ID: mbeEventId },
    query: {
      relationships: "internalClubs",
    },
  }).then(res => ({
    data: formatMBEEvent(res.data),
    teamsMap: Object.fromEntries(
      // @ts-expect-error TODO: Fix this
      Object.entries(res.data.relationships).map(([key, value]) => [key, formatTeam(value)]),
    ),
  }));
}

export async function getReplayEvents(query: IRequestReplayEventsParams) {
  return useAPI<{ data: ReplayEvent[] }>("api/v1/external/replay/events", { query });
}

export const getCachedEventById = useMemoize((eventId: string, options?: { accessToken?: string }) =>
  getEventById(eventId, options),
);

export async function getEventById(eventId: string, options?: { accessToken?: string }): Promise<IEvent> {
  const baseURL = useRuntimeConfig().app.baseURL;
  const location = await useLocation();
  const isUS = location.country === "US";

  if (eventId === "demo")
    return $fetch<IResponseEvent>(`${baseURL}mocks/data/event/123/data.json`).then(res => formatEvent(res, { isUS }));

  return useAPI<{ data: IResponseEvent }>(`/api/v1/events/${eventId}`, {
    ...(options?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${options.accessToken}` } }),
    query: {
      relationships: "club,favorite,highlight.favorite",
      with: "homeTeam,awayTeam",
    },
  }).then(res => formatEvent(res.data, { isUS }));
}

export function sendEventForBreakdown(eventId: string, breakdownType: IBreakdownType) {
  return useAPI(`api/v1/events/${eventId}/breakdowns/${breakdownType}`, { method: "POST" });
}

export async function getVideo(
  videoId: IBasicVideo["id"],
  type: IBasicVideo["type"],
  options?: { accessToken?: string },
): Promise<IEvent | IClip | IGameHighlight | IAthleteHighlight> {
  const videoGetUrl = {
    "event": () => getCachedEventById(videoId, options),
    "clip": () => getUserClipById(videoId, options),
    "highlight": () => getEventHighlightById(videoId, options),
    "autohighlight": () => getEventHighlightById(videoId, options),
    "player-highlight": () => getEventAthleteHighlightById(videoId, options),
    "mbe-event": () => getCachedEventById(videoId, options), // Not used / not valid
  } as const;

  return videoGetUrl[type]();
}

function hashCode(str: string): string {
  let hash: number = 0;
  for (let i: number = 0, len = str.length; i < len; i++) {
    const chr: number = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return String(Math.abs(hash));
}

export async function createEvent(data: TCreateEventPayload): Promise<IEvent> {
  return useAPI<{ data: IResponseEvent }>("/api/v1/events", {
    method: "POST",
    body: data,
  }).then(res => formatEvent(res.data));
}

export async function getVideoShareURL(
  video: IBasicVideo,
  options: { baseURL: string; accessToken?: string },
): Promise<string | undefined> {
  // Create hash from baseURL for uniq state param
  const baseURLHash = hashCode(options.baseURL);
  const sharedURL = useState(`shared-${video.type}-url-${video.id}-${baseURLHash}`, () => "");

  // Return the url from cache if such
  if (sharedURL.value) return sharedURL.value;

  const appConfig = useAppConfig();
  let accessToken = options?.accessToken;

  if (!accessToken) {
    const videoShareUrl = {
      "event": (id: string) => `/api/v1/events/${id}/share`,
      "clip": (id: string) => `/api/v1/clips/${id}/share`,
      "highlight": (id: string) => `/api/v1/events/highlights/${id}/share`,
      "autohighlight": (id: string) => `/api/v1/events/highlights/${id}/share`,
      "player-highlight": (id: string) => `/api/v1/player-highlights/${id}/share`,
      "mbe-event": (id: string) => `/api/v1/external/events/${id}/share`, // Not used / not valid
    } as const;

    accessToken = await useAPI<{ data: { token: string } }>(videoShareUrl[video.type](video.id), {
      method: "POST",
      body: video.type === "event" ? { stream: "hd" } : undefined,
    }).then(res => res.data.token);
  }

  // TODO: Move accessToken into the hash params
  const url = options.baseURL.includes("?")
    ? `${options.baseURL}&accessToken=${accessToken}`
    : `${options.baseURL}?accessToken=${accessToken}`;

  if (appConfig.useMockData) return Promise.resolve(url);

  const fallbackPoster = `${window.location.origin}/images/no_poster_light.jpg`;

  const title = `Watch "${video.title}" at Pixellot!`;
  const description = `Shared ${video.type} from Pixellot.`;
  const poster = !video.poster ? fallbackPoster : video.poster;

  const branchIO = useBranchIO();
  const linkData = {
    feature: `share-${video.type}`,
    data: {
      // general
      title: title,
      description: description,
      image: poster,
      eventId: video.id,
      token: accessToken,
      action: "shared_video",
      video_type: video.type,
      // branchIO specific
      $og_title: title,
      $og_description: description,
      $og_image_url: poster,
      $og_url: url,
      $desktop_url: url,
      $ios_url: url,
      $ipad_url: url,
      $android_url: url,
      $windows_phone_url: url,
      $blackberry_url: url,
      $fire_url: url,
      $twitter_title: title,
      $twitter_description: description,
      $twitter_image_url: poster,
      // meta tags
      $custom_meta_tags: JSON.stringify({
        title: title,
        description: description,
        canonical: url,
        url: url,
        ogUrl: url,
        oEmbedImage: poster,
        ogTitle: title,
        ogDescription: description,
        ogImage: poster,
        twitterTitle: title,
        twitterDescription: description,
        twitterImage: poster,
        twitterCard: "summary_large_image",
      }),
    },
  };

  sharedURL.value = await branchIO.link(linkData);

  return sharedURL.value;
}

export async function requestExport(eventId: string, options?: { stream?: IPixellotPlayerSourceType }): Promise<void> {
  const body = { mimeType: "video/mp4", stream: options?.stream };
  return useAPI(`/api/v1/events/${eventId}/export`, { method: "POST", body });
}

export async function deleteEvent(eventId: string): Promise<void> {
  return useAPI(`/api/v1/events/${eventId}`, { method: "DELETE" }).then(() => getCachedEventsList.clear());
}

export function getEditLineupUrl(
  eventId: string,
  options?: {
    backUrl?: string;
  },
): Promise<string> {
  return useAPI<{ data: { link: string; expiresAt: number } }>(`/api/v1/events/${eventId}/lineups/auth`, {
    query: { backUrl: options?.backUrl },
  }).then(res => res.data.link);
}

export function getAdvancedEditorUrl(
  eventId: string,
  options?: {
    backUrl?: string;
  },
): Promise<string> {
  return useAPI<{ data: { link: string; expiresAt: number } }>(`/api/v1/events/${eventId}/editor/auth`, {
    query: { backUrl: options?.backUrl },
  }).then(res => res.data.link);
}

export function getMatchOverviewUrl(eventId: string): Promise<string> {
  return useAPI<{ data: { link: string; expiresAt: number } }>(`/api/v1/events/${eventId}/playlists/auth`).then(
    res => res.data.link,
  );
}

export function getMatchChartsUrl(eventId: string): Promise<string> {
  return useAPI<{ data: { link: string; expiresAt: number } }>(`/api/v1/events/${eventId}/charts/auth`).then(
    res => res.data.link,
  );
}

export type TUpdateEventPayload = components["schemas"]["UpdateEventAttr"];

export async function updateEvent(eventId: string, data: TUpdateEventPayload): Promise<void> {
  return useAPI<{ data: IResponseEvent }>(`/api/v1/events/${eventId}`, {
    method: "PATCH",
    body: data,
  }).then(() => {
    getCachedEventById.delete(eventId);
  });
}
