import type { ITelestration } from "~/types";

/**
 * Converts an SVG string into a Base64-encoded data URL.
 *
 * @param telestrations - The SVG string to be converted.
 * @returns Telestrations with time inside the range of 0.29 seconds before and 0.05 seconds after the start time.
 *
 * @example
 * ```typescript
 * const currentTelestrations = filterTelestrationsTimeIntersection(telestrations, currentTime);
 * ```
 */

export function filterTelestrationsTimeIntersection(telestrations: ITelestration[], currentTime: number): ITelestration[] {
  return telestrations.filter(
    (telestration) => {
      const time = Number(telestration.start);
      const lowerBound = time - 0.29;
      const upperBound = time + 0.05;
      return currentTime >= lowerBound && currentTime <= upperBound;
    },
  );
}
