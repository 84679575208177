import {
  formatClipVideoUrls,
  formatEventVideoUrls,
  formatHighlightVideoUrls,
  formatInlineHighlightVideoUrls,
  formatMBEEventVideoUrls,
  formatPlayerHighlightVideoUrls,
} from "./format-video-urls";
import { EVENT_STATUS, VIDEO_TYPE } from "~/constants";
import type {
  IAthleteHighlight,
  IBasicVideo,
  IClip,
  IEvent,
  IEventAthleteStats,
  IEventGameStats,
  IEventScoreStats,
  IGameHighlight,
  IGameStats,
  IResponsePlaylist,
  IResponseClip,
  IResponseEvent,
  IResponseEventStatsRosters,
  IResponseEventStatsScores,
  IResponseEventStatsTeams,
  IResponseFavoriteVideo,
  IResponseHighlight,
  IResponsePlayerHighlight,
  IResponseTag,
  ITag,
  IPlaylist,
  IResponseMBEEvent,
  IMbeEvent,
  TSportType,
  IEventGameType,
} from "~/types";
import { formatPlaylist } from "~/videos/helpers/format-playlist";
import { getStatValueV3 } from "~/videos/helpers/get-stat-value";

const allowedSportTypesForPDFExport: TSportType[] = [
  "basketball",
  "volleyball",
  "hockey",
  "fieldhockey",
  "soccer",
  "futsal",
  "handball",
];

export default function formatEvent(event: IResponseEvent, options?: { leagueId?: string; isUS?: boolean }): IEvent {
  const sportType = event.attributes.gameInfo.name;
  const gameInfo = formatEventGameInfo(event.attributes.gameInfo, { isUS: options?.isUS });

  const data: IEvent = {
    id: event.id,
    eventId: event.id,
    eventAbeId: event.attributes.external?.abe,
    favoriteId: event.relationships?.favorite?.id,
    type: "event",
    source_type: "video",
    title: event.attributes.name,
    created_at: event.meta.createdAt,
    start_date: event.attributes.startDateTime,
    season: event.attributes.season,
    status: event.attributes.status === "upcoming_live" ? "upcoming" : event.attributes.status,
    sport_type: sportType,
    views_count: event.attributes.statistics?.viewCount || 0,
    video_duration: event.attributes.duration,
    teamId: event.attributes.clubId,
    leagueId: options?.leagueId,
    poster: event.attributes.videosList.find(v => v.thumbnail)?.thumbnail || event.attributes.thumbnails.hd || "",
    urls: formatEventVideoUrls(event),
    game_info: gameInfo,
    breakdowns: {
      type: event.attributes.breakdowns?.type || null,
      status: event.attributes.breakdowns?.status || null,
    },
    permissions: {
      canExportStatsPDF:
        isFeatureEnabled("enable_event_stats_export_to_pdf") && allowedSportTypesForPDFExport.includes(sportType),
      canEdit: (event.attributes.status === EVENT_STATUS.VOD && event.meta.permissions?.includes("write")) || false,
      canExport: event.meta.permissions?.includes("export") || false,
      canAddToFavorites: event.meta.permissions?.includes("favorite") || false,
      canOpenVS: (Boolean(event.relationships?.club?.attributes.external?.vidswap) && event.meta.permissions?.includes("deeplink:overview")) || false,
      canOpenChartsVS: (Boolean(event.relationships?.club?.attributes.external?.vidswap) && event.meta.permissions?.includes("deeplink:chart")) || false,
      canOpenEditorVS: (Boolean(event.relationships?.club?.attributes.external?.vidswap) && event.meta.permissions?.includes("deeplink:editor")) || false,
      canEditOnVS: (Boolean(event.relationships?.club?.attributes.external?.vidswap) && event.meta.permissions?.includes("deeplink:lineup")) || false,
      canSendForBreakdown: event.meta.permissions?.includes("write") || false,
      canEditTag: event.meta.permissions?.includes("tag:write") || false,
      canDeleteTag: event.meta.permissions?.includes("tag:delete") || false,
      canDelete: event.meta.permissions?.includes("delete") || false,
      canShare: event.meta.permissions?.includes("share") || false,
      canAddHomeGameInfo: isFeatureEnabled("enable_event_add_game_info") && !gameInfo.team1_is_official,
      canAddAwayGameInfo: isFeatureEnabled("enable_event_add_game_info") && !gameInfo.team2_is_official,
      canAddLineup: isFeatureEnabled("enable_event_add_lineup"),
      availableStats: event.meta.permissions?.includes("stats:overview") || false,
      availableLeaders: isFeatureEnabled("enable_event_leaders") || false,
      availableAthletesStats: isFeatureEnabled("enable_event_athlete_stats_tab") || false,
      availableTeamStats: isFeatureEnabled("enable_event_team_stats_tab") || false,
      availableAthleteStats: isFeatureEnabled("enable_event_athlete_stats_tab") || false,
    },
    is_favorite: Boolean(event.relationships?.favorite),
    external: {
      abe: event.attributes.external?.abe,
      vidswap: event.attributes.external?.vidswap,
    },
  };

  const auto_highlight = event.attributes.highlights.find(h => h.type === "autohighlight");

  if (auto_highlight) data.auto_highlight = formatInlineHighlight(auto_highlight, event);

  return data;
}

export function formatMBEEvent(event: IResponseMBEEvent): IMbeEvent {
  const data: IMbeEvent = {
    id: event.id,
    eventId: event.id,
    type: "mbe-event",
    source_type: "video",
    title: event.attributes.name || "",
    created_at: "", // TODO: Add when support will be added
    start_date: event.attributes.startDate as unknown as Date,
    end_date: event.attributes.endDate as unknown as Date,
    status: "vod",
    sport_type: event.attributes.sportType as TSportType,
    urls: formatMBEEventVideoUrls(event),
    views_count: 0,
    is_favorite: false,
    game_info: {
      team1_name: event.attributes.teams?.homeTeamName || "",
      // @ts-expect-error ... TODO: Update this in atlit swagger
      team1_score: event.attributes.teams?.homeScore,
      team1_logo_url: event.attributes.teams?.homeTeamLogo,
      team1_color: event.attributes.teams?.homeTeamColor,
      team1_id: event.attributes.teams?.homeTeamId,
      team2_name: event.attributes.teams?.awayTeamName || "",
      // @ts-expect-error ... TODO: Update this in atlit swagger
      team2_score: event.attributes.teams?.awayScore,
      team2_logo_url: event.attributes.teams?.awayTeamLogo,
      team2_color: (event.attributes.teams?.awayTeamColor as unknown as string) || "",
      team2_id: event.attributes.teams?.awayTeamId,
      type: event.attributes.gameType as IEventGameType,
    },
    permissions: {
      canAddToFavorites: false,
    },
  };

  return data;
}

function formatEventGameInfo(
  info: IResponseEvent["attributes"]["gameInfo"],
  options?: { isUS?: boolean },
): IEvent["game_info"] {
  return {
    team1_name: options?.isUS ? info.team2 : info.team1,
    team1_score: options?.isUS ? info.score2 : info.score1,
    team1_logo_url: options?.isUS ? info.team2Logo : info.team1Logo,
    team1_color: options?.isUS ? info.team2Color : info.team1Color,
    team1_id: options?.isUS ? info.team2Id : info.team1Id,
    // @ts-expect-error ... TODO: Update this in atlit swagger
    team1_is_official: info?.[options?.isUS ? "awayTeam" : "homeTeam"]?.attributes?.isOfficialTeam ?? true,
    team2_name: options?.isUS ? info.team1 : info.team2,
    team2_score: options?.isUS ? info.score1 : info.score2,
    team2_logo_url: options?.isUS ? info.team1Logo : info.team2Logo,
    team2_color: options?.isUS ? info.team1Color : info.team2Color,
    team2_id: options?.isUS ? info.team1Id : info.team2Id,
    // @ts-expect-error ... TODO: Update this in atlit swagger
    team2_is_official: info?.[options?.isUS ? "homeTeam" : "awayTeam"]?.attributes?.isOfficialTeam ?? true,
    type: info.type,
    lineup: info.lineup.map(l => l.attributes),
  };
}

export function formatTag(tag: IResponseTag): ITag {
  return {
    id: tag.id,
    title: tag.attributes.name || capitalize(tag.attributes.type),
    type: tag.attributes.type,
    playerNumber: tag.attributes.player === null ? undefined : tag.attributes.player,
    startTime: tag.attributes.startTime,
    endTime: tag.attributes.endTime,
  };
}

export function formatClip(data: IResponseClip): IClip {
  return {
    id: data.id,
    ownerId: data.attributes.userId,
    eventId: data.attributes.eventId,
    favoriteId: data.relationships?.favorite?.id,
    type: "clip",
    source_type: "video",
    title: data.attributes.name,
    created_at: data.meta.createdAt,
    views_count: data.attributes.statistics.viewCount || 0,
    isPending: data.attributes.status === "creating" || !data.attributes.urls.clip,
    poster: data.attributes.urls.thumbnail,
    urls: formatClipVideoUrls(data),
    is_favorite: Boolean(data.relationships?.favorite),
    permissions: {
      canDelete: data.meta.permissions?.includes("delete") || false,
      canAddToFavorites: data.meta.permissions?.includes("favorite") || false,
    },
  };
}
export function formatInlineHighlight(
  data: IResponseEvent["attributes"]["highlights"][0],
  event: IResponseEvent,
): IGameHighlight {
  return {
    id: data.id,
    type: data.type,
    source_type: "video",
    eventId: event.id,
    favoriteId: data.relationships?.favorite?.id,
    title: data.name || (data.type === "autohighlight" ? "Game recap" : data.type),
    created_at: event.attributes.endDateTime,
    views_count: data.statistics.viewCount || 0,
    poster: data.thumbnail,
    urls: formatInlineHighlightVideoUrls(data),
    is_favorite: Boolean(data.relationships?.favorite),
    permissions: {
      canDelete: data.meta?.permissions?.includes("delete") || false,
      canAddToFavorites: data.meta?.permissions?.includes("favorite") || false,
    },
  };
}

export function formatHighlight(data: IResponseHighlight): IGameHighlight {
  return {
    id: data.id,
    type: data.attributes.type || data.type,
    source_type: "video",
    eventId: data.attributes.eventId,
    favoriteId: data.relationships?.favorite?.id,
    title: data.attributes.name || "Game recap",
    created_at: data.meta.createdAt,
    views_count: data.attributes.statistics.viewCount || 0,
    poster: data.attributes.urls.thumbnail,
    urls: formatHighlightVideoUrls(data),
    is_favorite: Boolean(data.relationships?.favorite),
    permissions: {
      canDelete: data.meta.permissions?.includes("delete") || false,
      canAddToFavorites: data.meta.permissions?.includes("favorite") || false,
    },
  };
}
export function formatAthleteHighlight(data: IResponsePlayerHighlight): IAthleteHighlight {
  return {
    id: data.id,
    type: "player-highlight",
    source_type: "video",
    eventId: data.attributes.eventId,
    favoriteId: data.relationships?.favorite?.id,
    title: `#${data.attributes.team.player}`,
    teamColor: data.attributes.team.color,
    playerNumber: data.attributes.team.player,
    created_at: data.meta.createdAt,
    views_count: data.attributes.statistics.viewCount || 0,
    poster: data.attributes.urls.thumbnail,
    urls: formatPlayerHighlightVideoUrls(data),
    is_favorite: Boolean(data.relationships?.favorite),
    permissions: {
      canDelete: data.meta.permissions?.includes("delete") || false,
      canAddToFavorites: data.meta.permissions?.includes("favorite") || false,
    },
  };
}

export function formatFavoriteVideo(item: IResponseFavoriteVideo): IBasicVideo | IPlaylist {
  const favoriteData = { favoriteId: item.relationships?.favorite?.id, is_favorite: true };

  if (item.type === VIDEO_TYPE.EVENT) {
    return Object.assign({}, formatEvent(item as IResponseEvent), favoriteData);
  }
  else if (item.type === VIDEO_TYPE.CLIP) {
    return Object.assign({}, formatClip(item as IResponseClip), favoriteData);
  }
  else if (item.type === VIDEO_TYPE.GAME_HIGHLIGHT) {
    return Object.assign({}, formatHighlight(item as IResponseHighlight), favoriteData);
  }
  else if (item.type === VIDEO_TYPE.ATHLETE_HIGHLIGHT) {
    return Object.assign({}, formatAthleteHighlight(item as IResponsePlayerHighlight), favoriteData);
  }
  else if (item.type === VIDEO_TYPE.PLAYLIST) {
    return Object.assign({}, formatPlaylist(item as IResponsePlaylist), favoriteData);
  }

  // TODO: Handle additional cases
  return Object.assign({}, formatClip(item as IResponseClip), favoriteData);
}

export function formatScoreStats(data: IResponseEventStatsScores): IEventScoreStats {
  return {
    team_home: data.data.attributes.homeTeam,
    team_away: data.data.attributes.awayTeam,
    stats: data.data.attributes.stats,
  };
}
export function formatGameStats(data: IResponseEventStatsTeams): IEventGameStats {
  return {
    team_home: data.data.attributes.homeTeam,
    team_away: data.data.attributes.awayTeam,
    stats: data.data.attributes.stats.map(formatGameStat),
  };
}
export function formatAthleteStats(data: IResponseEventStatsRosters): IEventAthleteStats {
  return {
    team_home: data.data.attributes.homeTeam,
    team_away: data.data.attributes.awayTeam,
    stats: data.data.attributes.stats,
  };
}

function formatGameStat(statItem: IResponseEventStatsTeams["data"]["attributes"]["stats"][0]): IGameStats {
  return {
    name: statItem.name,
    shortName: statItem.shortName,
    homeTeamValue: getStatValueV3(statItem.homeTeamValue),
    awayTeamValue: getStatValueV3(statItem.awayTeamValue),
    category: statItem.category,
    homeTeamUrl: statItem.homeTeamUrl,
    awayTeamUrl: statItem.awayTeamUrl,
    homePlaylistId: statItem.homePlaylistId,
    awayPlaylistId: statItem.awayPlaylistId,
    maxValue: statItem.maxValue,
  };
}
