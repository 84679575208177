<script setup lang="ts">
const telestrationStore = useTelestrationStore();
</script>

<template>
  <div class="relative">
    <TShapesToolbar v-show="telestrationStore.telestrationMode === TelestrationMode.EDIT" />
    <TStage />
    <TShapesTransformer />
  </div>
</template>
