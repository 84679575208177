<script setup lang="ts">
import { ShapeStrokeColors } from "~/telestrations/constants/colors";

const { t } = useI18n();
const shapesStore = useShapesStore();

const options = computed(() => {
  if (shapesStore.selectedShape) {
    return ShapeStrokeColors[shapesStore.selectedShape.type];
  }

  return [];
});
const selectedStrokeColor = computed(() => {
  if (shapesStore.selectedShape) {
    return shapesStore.selectedShape.stroke as string;
  }

  return options.value[0];
});

function onShapeStrokeChange(value: string) {
  if (!shapesStore.selectedShape) {
    return;
  }

  shapesStore.updateShape(shapesStore.selectedShape, { stroke: value });
}
</script>

<template>
  <template v-if="options.length">
    <USelectMenu
      v-model="selectedStrokeColor"
      :options="options"
      input-class="!border-none !gap-x-2 pl-1"
      list-class="grid grid-cols-2 p-2 gap-x-1 gap-y-2"
      variant="inline"
      :divider="false"
      :hide-trailing-icon="true"
      item-class="min-h-max rounded"
      container-class="p-1"
      @update:model-value="onShapeStrokeChange"
    >
      <template #option="{ option }">
        <div class="rounded-full border p-0.5" :style="{ borderColor: selectedStrokeColor === option.value ? selectedStrokeColor : 'transparent' }">
          <div class="size-6 rounded-full" :style="{ backgroundColor: option.value }" />
        </div>
      </template>
      <UIcon
        v-tooltip="t('labels.change_stroke_color')"
        name="stroke-color"
        class="size-6"
        :style="{ backgroundColor: selectedStrokeColor }"
      />
    </USelectMenu>
  </template>
</template>
