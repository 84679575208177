/**
 * Converts an SVG string into a Base64-encoded data URL.
 *
 * @param svgString - The SVG string to be converted.
 * @returns A Base64-encoded data URL representing the SVG image.
 *
 * @remarks
 * This function encodes the provided SVG string using `btoa` and formats it
 * as a data URL with the MIME type `image/svg+xml`.
 *
 * @example
 * ```typescript
 * const svg = '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><circle cx="50" cy="50" r="40" fill="red" /></svg>';
 * const dataUrl = convertSvgToBase64DataUrl(svg);
 * console.log(dataUrl); // Outputs: data:image/svg+xml;base64,...
 * ```
 */
export function svgStringToBase64Url(svgString: string): string {
  const base64Encoded = btoa(svgString);
  const dataUrl = `data:image/svg+xml;base64,${base64Encoded}`;
  return dataUrl;
}
