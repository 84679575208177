/**
 * Converts a base64 or URL-encoded SVG data URL into an SVG string.
 *
 * This function takes a data URL containing SVG content and decodes it into a usable SVG string.
 * It supports both base64-encoded and URL-encoded SVG data URLs.
 *
 * @param dataUrl - The data URL containing the SVG content.
 * @returns The decoded SVG string if successful, or `null` if the input is invalid or decoding fails.
 *
 * @example
 * // Example with a base64-encoded SVG data URL
 * const base64DataUrl = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0icmVkIiAvPjwvc3ZnPg==";
 * const svgString = base64UrlToSVGString(base64DataUrl);
 * console.log(svgString);
 * // Output: <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><circle cx="50" cy="50" r="50" fill="red" /></svg>
 *
 * @example
 * // Example with a URL-encoded SVG data URL
 * const urlEncodedDataUrl = "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%22%20height%3D%22100%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22red%22%20%2F%3E%3C%2Fsvg%3E";
 * const svgString = base64UrlToSVGString(urlEncodedDataUrl);
 * console.log(svgString);
 * // Output: <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><circle cx="50" cy="50" r="50" fill="red" /></svg>
 *
 * @example
 * // Example with an invalid data URL
 * const invalidDataUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUA";
 * const svgString = base64UrlToSVGString(invalidDataUrl);
 * console.log(svgString);
 * // Output: null
 */
export function base64UrlToSVGString(dataUrl?: string): string | null {
  // Check if the input is a valid string
  if (typeof dataUrl !== "string") {
    return null; // Invalid input
  }
  // Trim whitespace from the input string
  dataUrl = dataUrl.trim();
  // Check if the input is empty
  if (dataUrl.length === 0) {
    return null; // Empty string
  }
  // Validate that the input is an SVG data URL
  if (!dataUrl.startsWith("data:image/svg+xml")) {
    return null; // Not an SVG data URL
  }

  // Split the data URL into metadata and encoded content
  const [metadata, ...dataParts] = dataUrl.split(",");
  if (!metadata || dataParts.length === 0) {
    return null; // Invalid data URL format
  }

  const encodedData = dataParts.join(",");
  const isBase64Encoded = metadata.includes("base64");

  try {
    // Decode based on the encoding type
    if (isBase64Encoded) {
      return atob(encodedData); // Decode base64-encoded data
    }
    else {
      // Decode URL-encoded data and replace single quotes with double quotes
      return decodeURIComponent(encodedData).replace(/'/g, "\"");
    }
  }
  catch (error) {
    console.error("Error decoding SVG data URL:", error);
    return null; // Return null if decoding fails
  }
}
