import type { IRequestPaginateParams } from "./events";
import type { IPlaylist, ITag, IResponsePlaylistItem, IPlaylistItem, IRequestPayloadTag } from "~/types";
import { formatPlaylist, formatPlaylistItem } from "~/videos/helpers/format-playlist";
import getSharedHeaders from "~/common/helpers/get-headers";

export const getCachedUserPlaylists = useMemoize(
  (opts: { limit: number; offset: number; locale?: string }): Promise<{ data: IPlaylist[]; total: number }> => getUserPlaylists(opts),
);

export function getUserPlaylists(opts: {
  limit: number;
  offset: number;
  locale?: string;
}): Promise<{ data: IPlaylist[]; total: number }> {
  return $atlitFetch("/v1/users/me/playlists", {
    query: {
      limit: opts.limit,
      offset: opts.offset,
      relationships: "favorite",
    },
    headers: getSharedHeaders({ locale: opts.locale }),
  }).then(response => ({
    data: response.data.map(formatPlaylist),
    total: response.meta?.total || response.data.length,
  }));
}

export function getUserPlaylistById(id: string, options: { accessToken?: string; locale?: string }): Promise<IPlaylist> {
  return $atlitFetch("/v1/users/me/playlists/{ID}", {
    path: {
      ID: id,
    },
    headers: getSharedHeaders(options),
  }).then(response => formatPlaylist(response.data));
}

export function createPlaylist(name: string): Promise<IPlaylist> {
  return $atlitFetch("/v1/playlists", {
    method: "POST",
    body: { name },
  }).then((response) => {
    getCachedUserPlaylists.clear();
    return formatPlaylist(response.data);
  });
}

export function updatePlaylist(id: string, name: string) {
  return useAPI(`api/v1/playlists/${id}`, { method: "PATCH", body: { name } }).then(() => {
    getCachedUserPlaylists.clear();
  });
}

export function deletePlaylist(id: string) {
  return useAPI(`api/v1/playlists/${id}`, { method: "DELETE" }).then(() => {
    getCachedUserPlaylists.clear();
  });
}

export function addTagsToPlaylist(
  tags: ITag[] | IPlaylistItem[],
  playlists: IPlaylist[],
  sourceId: string,
  sourceType?: string,
  options?: { accessToken: string; locale?: string },
) {
  const source = sourceType || "event";
  const ids = tags.map(item => `${item.id}`);
  const requests = playlists.map((item) => {
    return useAPI(`api/v1/playlists/${item.id}/items`, {
      method: "POST",
      body: { ids, sourceId, source },
      headers: getSharedHeaders(options),
    });
  });
  // TODO remove promise.all() when BE bulk request will be ready
  return Promise.all(requests);
}

export async function getEventUserPlaylistsById(
  eventId: string,
  teamId: string | undefined,
  options: IRequestPaginateParams & { accessToken?: string; locale?: string },
): Promise<{ data: IPlaylist[]; total: number }> {
  return $atlitFetch("/v1/events/{ID}/playlists", {
    path: {
      ID: eventId,
    },
    query: {
      limit: options.limit,
      offset: options.offset,
      // @ts-expect-error TODO: Should be added in the `atlit-be` swagger definition
      teamId,
    },
    headers: getSharedHeaders(options),
  }).then(response => ({
    data: response.data.map(formatPlaylist),
    total: response.meta?.total || response.data.length,
  }));
}

export function getPlaylistItems(id: string, options?: { accessToken?: string; locale?: string }): Promise<IPlaylistItem[]> {
  return useAPI<{ data: IResponsePlaylistItem[] }>(`api/v1/playlists/${id}/items`, {
    headers: getSharedHeaders(options),
  }).then(response => response.data.map(item => formatPlaylistItem(item, id)));
}

export function updatePlaylistItemOrder(itemId: string, position: number) {
  return useAPI(`api/v1/playlists/items/${itemId}`, { method: "PATCH", body: { position } });
}

export function deletePlaylistItem(playlistItemId: string) {
  return useAPI(`api/v1/playlists/items/${playlistItemId}`, { method: "DELETE" });
}

export function deletePlaylistItems(playlistItemIds: string[]) {
  const requests = playlistItemIds.map(id => deletePlaylistItem(id));

  return Promise.all(requests);
}

export function editPlaylistItem(itemId: string, body: Partial<IRequestPayloadTag>): Promise<IPlaylistItem> {
  return useAPI<{ data: IResponsePlaylistItem }>(`api/v1/playlists/items/${itemId}`, { method: "PATCH", body }).then(
    // TODO: pass actual playlistId here
    response => formatPlaylistItem(response.data, itemId),
  );
}

export async function getPlaylistShareURL(
  playlist: IPlaylist,
  options: { baseURL: string; accessToken?: string },
): Promise<string | undefined> {
  const sharedURL = useState(`shared-playlist-url-${playlist.id}`, () => "");

  // Return the url from cache if such
  if (sharedURL.value) return sharedURL.value;

  const appConfig = useAppConfig();
  let accessToken = options?.accessToken;

  if (!accessToken) {
    accessToken = await useAPI<{ data: { token: string } }>(`api/v1/playlists/${playlist.id}/share`, {
      method: "POST",
    }).then(res => res.data.token);
  }

  // TODO: Move accessToken into the hash params
  const url = options.baseURL.includes("?")
    ? `${options.baseURL}&accessToken=${accessToken}`
    : `${options.baseURL}?accessToken=${accessToken}`;

  if (appConfig.useMockData) return Promise.resolve(url);

  const branchIO = useBranchIO();
  const linkData = {
    feature: "share-playlist",
    data: {
      playlistId: playlist.id,
      token: accessToken,
      action: "shared_playlist",
      playlist_type: playlist.type,
      $og_title: `Watch Playlist "${playlist.name}" at Pixellot!`,
      $og_image_url: "",
      $og_url: url,
      $desktop_url: url,
      $ios_url: url,
      $ipad_url: url,
      $android_url: url,
      $windows_phone_url: url,
      $blackberry_url: url,
      $fire_url: url,
    },
  };

  sharedURL.value = await branchIO.link(linkData);

  return sharedURL.value;
}
