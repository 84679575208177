import type Konva from "konva";
import { ShapeType, type CommonShapeConfig, type Shape } from "../types/canvas";
import { InitialShapeColor } from "./colors";

const commonConfig: Konva.ShapeConfig = {
  draggable: true,
  rotation: 0,
};

const ShapeConfig: Record<ShapeType, (params: CommonShapeConfig) => Konva.ShapeConfig> = {
  [ShapeType.Rectangle]: ({ type, startX, startY }) => ({
    ...InitialShapeColor[type],
    x: startX,
    y: startY,
    width: 0,
    height: 0,
    strokeWidth: 4,
  }),
  [ShapeType.Circle]: ({ type, startX, startY }) => ({
    ...InitialShapeColor[type],
    x: startX,
    y: startY,
    radius: 0,
    strokeWidth: 4,
  }),
  [ShapeType.Line]: ({ type, startX, startY }) => ({
    ...InitialShapeColor[type],
    strokeWidth: 4,
    lineCap: "round",
    lineJoin: "round",
    points: [startX, startY],
  }),
  [ShapeType.Arrow]: ({ type, startX, startY }) => ({
    ...InitialShapeColor[type],
    strokeWidth: 3,
    points: [startX, startY, startX, startY],
  }),
  [ShapeType.Text]: ({ type, startX, startY }) => ({
    ...InitialShapeColor[type],
    x: startX,
    y: startY,
    text: "New Text",
    fontSize: 20,
    fontFamily: "Arial",
  }),
};

export const getShapeConfig = (payload: CommonShapeConfig, overrides: Partial<Shape> = {}): Shape => {
  const shape: Shape = {
    id: payload.id,
    layerIndex: payload.layerIndex,
    type: payload.type,
    startX: payload.startX,
    startY: payload.startY,
    ...commonConfig,
    ...ShapeConfig[payload.type](payload),
    ...overrides,
  };

  return shape;
};
