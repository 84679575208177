import type { components } from "#build/types/nuxt-open-fetch/atlit";

export function formatTelestration(
  telestration: components["schemas"]["TelestrationPlay"],
) {
  return {
    id: telestration.id,
    targetId: telestration.attributes.targetId,
    eventId: telestration.attributes.eventId,
    owner: telestration.attributes.owner,
    start: telestration.attributes.start,
    body: telestration.attributes.body,
  };
}
