<script lang="ts" setup>
import type { ShallowRef } from "vue";
import { twMerge } from "tailwind-merge";
import { cva, type VariantProps } from "class-variance-authority";
import type { RouteLocationRaw } from "#vue-router";
import type { PxlIcon } from "@/common/components/U/Icon";
import type { MenuState } from "@/common/components/U/Menu/Menu.vue";
import type { UAvatar } from "#components";
import { NuxtLink, UDivider } from "#components";
import { iconBind } from "@/common/components/U/Icon";

type UAvatarProps = InstanceType<typeof UAvatar>["$props"];

const listItem = cva(
  "relative flex w-full items-center justify-between gap-4 px-4 py-2 text-sm font-medium text-black transition-colors duration-100 dark:text-white",
  {
    variants: {
      size: {
        auto: "min-h-auto",
        sm: "min-h-[35px]",
        md: "min-h-11",
        lg: "min-h-[51px]",
      },
      active: {
        true: "bg-neutral-light-100 dark:bg-neutral-dark-500",
      },
      disabled: {
        true: "cursor-not-allowed text-neutral-light-700 dark:text-neutral-dark-400",
      },
      hover: {
        true: "cursor-pointer hover:bg-neutral-light-100 hover:dark:bg-neutral-dark-500",
      },
    },
    defaultVariants: {
      size: "sm",
    },
  },
);

type ListItemProps = VariantProps<typeof listItem>;

const props = withDefaults(
  defineProps<{
    to?: RouteLocationRaw;
    href?: string;
    text?: string;
    icon?: PxlIcon;
    leadingIcon?: PxlIcon;
    trailingIcon?: PxlIcon;
    tooltip?: string;
    active?: boolean;
    disabled?: boolean;
    size?: ListItemProps["size"];
    onClick?: (() => void) | (() => Promise<void>);
    onClickCloseMenu?: boolean;
    divider?: boolean;
    hasHover?: boolean;
    image?: UAvatarProps;
    containerClass?: string;
  }>(),
  {
    size: "sm",
    hasHover: true,
    onClickCloseMenu: true,
  },
);

const emit = defineEmits(["click"]);
const [isDisabled, toggleDisabled] = useToggle(props.disabled);
const menu = inject<ShallowRef<MenuState>>("ui.menu");

const component = computed(() => {
  if (props.to) return NuxtLink;
  if (props.href) return "a";
  return "li";
});

const tabIndex = computed(() => {
  if (component.value === "li" && !props.disabled && props.hasHover) return "0";
  return undefined;
});

const additionalProps = computed(() => {
  if (props.to) return { to: props.to };
  if (props.href) return { href: props.href, target: "_blank", rel: "noopener noreferrer" };
  return {};
});

const listItemClass = computed(() => {
  return twMerge(
    listItem({
      size: props.size,
      active: props.active,
      disabled: isDisabled.value,
      hover: props.hasHover && !isDisabled.value,
    }),
    props.containerClass,
  );
});

async function onItemClick(event: PointerEvent) {
  if (isDisabled.value) return;

  if (props.onClick) {
    toggleDisabled(true);
    await props.onClick();
    toggleDisabled(false);
  }
  else {
    emit("click", event);
  }

  if (menu?.value.close && props.onClickCloseMenu) {
    menu?.value.close();
  }
}
</script>

<template>
  <Component
    :is="component"
    :class="listItemClass"
    v-bind="additionalProps"
    :tabindex="tabIndex"
    @click="onItemClick"
    @keyup.enter="onItemClick"
  >
    <div class="flex flex-1 items-center gap-3">
      <slot name="leading">
        <UIcon
          v-if="props.leadingIcon || props.icon"
          v-bind="iconBind(props.leadingIcon || props.icon, { disabled: isDisabled })"
        />
      </slot>

      <slot name="image">
        <UAvatar v-if="props.image" v-bind="props.image" :size="20" />
      </slot>

      <slot>{{ props.text }}</slot>

      <div
        v-if="props.divider"
        class="absolute -bottom-px left-0 w-full px-4"
      >
        <UDivider lighter />
      </div>
    </div>

    <slot name="trailing">
      <UIcon
        v-if="props.tooltip"
        v-tooltip="props.tooltip"
        name="info"
      />
      <UIcon
        v-if="props.trailingIcon"
        v-bind="iconBind(props.trailingIcon, { disabled: isDisabled })"
      />
    </slot>
  </Component>
</template>
